.p-multiselect {
    border: 1px solid #797979;
    min-height: 4rem;
    max-height: fit-content;
    width: 100%;
    margin-top: 1px;
}

.p-multiselect-panel {
    background-color: #d2dcf3;
    width: min-content;
    height: 24rem;
}

.p-multiselect-header {
    background-color: #d2dcf3;
    padding-left: 0.5rem;
    padding-right: 1rem;
}

.p-multiselect-trigger {
    display: none;
}

.p-virtualscroller {
    height: 20rem !important;
}

.p-multiselect-trigger-icon {
    display: none;
}

.p-multiselect-close-icon {
    display: none;
}

.p-multiselect-close {
    background-color: #040b50;
    color: white;
    width: 5rem;
    border-radius: 5px;
}

.p-multiselect-close:before {
    content: "Done";
}

.p-multiselect-filter {
    padding-left: 0.5rem;
}

.p-checkbox-box {
    border: 1px solid black;
}

.p-multiselect-filter {
    border: 1px solid black;
}

.p-multiselect-select-all {
    line-height: 1;
}

.p-multiselect-label {
    flex-flow: row wrap;
    display: flex;
    min-height: fit-content;
    line-height: 1;
    align-items: center;
    padding: 4px 12px;
}

.p-multiselect-label-container {
    display: grid;
}

.p-multiselect-label-container:after {
    content: attr(add-more-text);
    margin-left: 1rem;
    color: #a1a1a1;
    font-style: italic;
}

.p-multiselect-label:after {
    content: attr(more-than-3-selections-text);
    height: 1rem;
}

.p-multiselect-token {
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 1px 1px 3px 1px;
    background-color: #d2dcf3;
    border-radius: 8px;
    border: 1px solid #000b57;
    padding: 8px 12px;
}

.p-multiselect-token-label {
    line-height: 1;
    text-wrap: balance;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000c57;
}

.p-multiselect-token-icon {
    color: #000c57;
    margin-left: 0.5rem;
    flex: 0 0 1rem;
}

.p-multiselect-item {
    height: 2.8rem !important;
    text-wrap: wrap;
    line-height: 1rem;
    white-space: normal;
}

.p-multiselect-item.p-highlight {
    background: transparent;
}

.p-highlight span {
    color: #212529;
}

.p-checkbox-box.p-highlight {
    background: #007bff;
}

.p-multiselect-innerbox {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.radiusSelect {
    border: 1px solid #797979;
    color: #000b57;
}

@media (max-width: 800px) {
    body {
        -webkit-overflow-scrolling: scroll;
    }

    .p-multiselect-header {
        background-color: white;
        padding-left: unset;
        padding-right: unset;
    }

    .p-multiselect-close {
        display: none;
    }

    .p-multiselect-panel {
        z-index: 100 !important;
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        width: 100dvw !important;
        height: 100dvh !important;
        border-radius: 0;
        max-width: 100vw;
        padding: 1rem 1rem 2rem 1rem;
        overflow: hidden;
        /* border: 1px solid rgba(121, 121, 121, 1); */
        /* background-color: rgba(255, 255, 255, 1); */
        border: 1px solid #797979;
        background-color: white;
        display: block !important; /* just for debugging */
    }

    .p-virtualscroller {
        height: 85% !important;
        overflow-x: hidden !important;
        text-wrap: wrap !important;
        border-radius: 10px;
        border: 1px solid #797979;
    }

    .p-virtualscroller-content {
        overflow-x: hidden !important;
        text-wrap: wrap !important;
        background-color: #d2dcf3;
        width: 95%;
    }

    .p-multiselect-filter-container {
        margin: unset;
    }

    .done-button {
        width: 100%;
        height: 40px;
        padding: 2px 2px 2px 2px;
        border-radius: 5px;
        background-color: #000b57;
        box-sizing: border-box;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        line-height: normal;
        margin: 1rem 0;
        font-size: 20px;
    }

    .p-multiselect-token-label {
        line-height: 1.4;
    }
}

@media (max-width: 1600px) {
    .p-multiselect-item {
        height: 3rem !important;
    }
}

@media (max-width: 1100px) {
    .p-multiselect-item {
        height: 3.2rem !important;
    }
}

@media (max-width: 1000px) {
    .p-multiselect-item {
        height: 4rem !important;
    }
}