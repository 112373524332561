.user-menu-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap:.5em;
    cursor: pointer;
}

.user-name-container {
    padding: 0px .5rem;
    font-family: "Barlow SemiBold", "Barlow", sans-serif;
    font-weight: 600;
    color: #040b50;
}

.menu-person {
    height: 2.25rem
}
.down-arrowhead {
    height: .65rem;
    width: .8rem;
    margin-bottom:3px;
    align-self:end;
}

.show-user-menu-items {
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: .7rem;
    border: .07rem solid #040b50;
    padding: 1rem;
    font-family: "Barlow SemiBold", "Barlow", sans-serif;
    font-weight: 600;
    color: #283c98;
    gap: .7rem;
    top: 4.5rem;
    right:4rem;
    z-index: 2;
}


.hide-user-menu-items {
    display: none;
}

.go-to-travelerhub-button {
    border: 1px #040b50 solid;
    min-height:100%;
    border-radius: 8px;
    background-color:  rgba(245, 178, 31, 0.3);
    color: #040b50;
    font-weight: 600;
    width:15rem;
    font-size: 17px;
    font-family: "Barlow SemiBold", "Barlow", sans-serif;
    max-height:fit-content;
    cursor: pointer;
}