.filterContainerDesktopRoot {
    width: 100%;
}

.filterContainerDesktopBackground {
    background-color: rgba(0,0,0,.5);
    width: 100%;
    /* height: 100vh; */
}

.filterContainerDesktopLightbox {
    background-color: white;
    padding: 1rem 1rem 0rem 1rem;
    /* margin-left: 3rem;
    margin-right: 3rem; */
    /* margin-top:4.5rem; */
}

.filtersDesktopLabel {
    color: #283c98;
    font-size: x-large;
    font-weight: bold;
    margin-bottom: 1em;
}