#license-info{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow:auto;
    .full-form{
        display: flex;
        flex-direction: column-reverse;
    }
  

    h2 {
        font-weight: bold;
        font-size: 1.25rem;
    }

    .form-container{
        display: flex;
        flex-direction: column;
        border: 1px solid #293069;
        background-color: white;
        border-radius:10px;
        padding: 2rem 2.5rem;
        overflow: auto;
        gap: 1rem;
        &.disabled {
            background-color: darkgray;
            opacity: .5;
            pointer-events: none;
            input,select {
                background-color:darkgray;
                opacity: .4;
                
            }
            .selection-item{
                background-color:darkgray;
            }
        }
    }

    .inner-container{
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
    }

    label {
        font-weight: 550;
        font-size: large;
    }

    .label-input-container {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    }

    .question {
        font-weight: 550;
        font-size: large;
        padding-bottom: 0.2rem;
    }

    select{
        font-size: 1.2rem;
        border-radius: 5px;
        height: 2rem;
        border:1px solid #000b57;
        background-color: white;
        color: #000b57;
        font-weight: 450;
    }

    .question-container{
        padding: 0rem;
        justify-content: flex-start;

    }

    .selections{
        justify-content: start;
    }

    .check-allied-container {
        display: flex;
        align-items: center;
        gap: .25rem;
        padding-right: .75rem;
        margin-bottom: 1rem;
    }

    @media (min-width: 801px) {

        h2 {
            font-family: "Barlow-Bold", "Barlow", sans-serif;
            font-size: 2em;
            margin: 0;
            padding-top:0.75rem;
            font-weight: bold;
        }

        .previousNextButtonHeader {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-top:1.5rem;
            padding-bottom:1.5rem;
            justify-content: space-between;
        } 

        .form-container {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            background-color: #f2f2f2;
            flex-wrap: wrap;
            width: 100%;
           
        }

        .inner-container{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .question-container{
            flex-wrap: wrap;
            display: column;
            justify-content: center;
            padding: 0rem;
            gap: 0.2rem;
        }

        .SingleBackgroundQuestion .main-question-container{
            display: flex;
            flex-direction: column;
            align-items: start;
        }
        .full-form{
            display: flex;
            flex-direction: column;
        }
    }

}