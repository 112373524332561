#TimesheetPayPeriods{
    border-radius: 10px; 
    border: 1px solid #040b50;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    padding: 1rem;
    gap: 1rem; 
    background-color: #e1e1e1;

    .pay-period-item {
        display:flex;
        background-color:white;
        border-radius:5px;
        border: 1px solid #040b50;

        text-align: center;
        align-items: center;

        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 16px;
        color: #040B50;
    }
  
    .parent-container {
      display:flex;
      justify-content:center;
      align-items:center;
      position:relative;
    }

    .image-container {
      left:0;
      padding-left:10px;
    }

    .centered-text {
      text-align:center;
      width:100%;
    } 
}
  
@media (min-width: 1250px) {
    /*Desktop overrides*/
    #TimesheetPayPeriods{ 
      background-color: white;
      width:300px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
     
    }
}
  