.buttonContainer {
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding:.5rem .5rem;
    background-color: #040b50;
    border-radius: 5px;
    font-family: "Barlow SemiBold", "Barlow", sans-serif;
    color: #ffffff;
    border: none;
    font-size: 20px;
    font-weight: 600;
    height: 64px;
}

li:has(#location-id--99){
    background-color: rgba(240, 179, 35, 0.44);
}

.p-multiselect-items:has(#location-id--99) {
    padding: 0;
}

.searchForJobsForm .topContainer {
    height: min-content;
    border: 1px solid rgba(121, 121, 121, 1);
    border-radius: 0px 0px 12px 12px;
    padding: 1rem 1rem 3rem 3rem;
    background-color: rgba(253, 253, 253, 0.705);
}

.professionSelect{
    height: 64px;
    width: 100%;
    margin-right: 100px;
    font-size: 1rem;
    padding: 2px 2px 2px 5px;
    border-radius: 5px;
    border: 1px solid #797979;
    background-color: #ffffff;
    box-sizing: border-box;
    font-family: "Barlow-Medium", "Barlow Medium", "Barlow", sans-serif;
    font-weight: 600;
}

.rowDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 3%;
}

.columnStyle {
    display:flex;
    flex-direction: column;
    width: 30%;
    padding-top: .5rem;
    gap:.4rem;
}

.label {
    display: block;
    font-size: 16px;
    color: #000b57;
}

.searchForJobsForm {
    min-width: 18rem;
}

.searchButton {
    display: flex;
    height: 100%;
    align-items: end;
}

.assignmentText{
    font-size: 18px;
    font-weight:600;
    padding: 5px 0px;
    text-align: left;
}

@media (max-width: 800px) {
    .columnStyle {
        width: 100%;
    }

    .searchForJobsForm .topContainer {
        padding: 1rem 1rem 5rem 1rem;   
    }

    .topContainer{
        overflow-x: hidden;
        margin: 0rem 1rem 4rem 1rem;
    }

    
}
