#modal-background {
    position: fixed;
    display:flex;       
    overflow: auto;
    justify-content: center;

    align-items: center;
    justify-content: center;
    @media (max-width: 1250px) {
        align-items: stretch;
        justify-content: stretch;
        padding: 0;

    }
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    border: none;
    z-index: 99;
    padding: 0rem 8rem 0rem 8rem;

    .profile-modal-container * {
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
    }

    .profile-modal-container {
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        display: flex;
        flex-direction: column;        
        background-color: white;
        border: none;
        border-radius: 10px;  
        max-height: 80vh;

        @media (max-width: 1250px) {
            border-radius: 0;
            max-height: unset;
            width:100vw;
        }
    }

    .profile-x-button{
        height: 2rem;
    }
    .profile-header {
        display: flex;
        justify-content: space-between;
        padding: 20px 1rem;
        background-color: #d2dcf3;
        border-bottom: 1px solid #797979;
        
        @media (min-width: 801px) {
            border-radius: 10px 10px 0 0;
        }
    }
    .profile-title {
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 1rem;
        padding-left: 13%;
        margin: auto;
        @media (min-width: 801px) {
            width: auto;
            text-align: unset;
            margin: auto 0.5rem;
            padding-left: 0rem;
            font-size: 1.5rem;
        }
    }
    .profile-title-buttons {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
    .profile-content{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;

        @media (min-width: 1251px) {
            padding: 0rem 1rem 0rem 1rem;
        }
    }
    .profile-footer{
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem;
        justify-content: center;
        padding: 1rem 1.75rem 1rem 1.75rem;
    }
    
    .cancel-button {
        font-weight: 600;
        font-style: normal;
        font-size: 1.25rem;
        padding: 0.4rem 2rem; 
        border-radius: 5px;
        border: 1px solid #040b50;  
        color:#040b50;
        background-color: #fefeff ;
        cursor: pointer;        
    }

    .save-button {
        background: rgb(4, 11, 80);
        color: white;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 20px;
        padding: 0.4rem 2rem;
        cursor: pointer;

        border: 1px solid #040b50;
        border-radius: 5px;
    }
    .save-button:disabled {
        background-color: rgb(156, 158, 178);
        color: #cfcfcf;
        border-color: rgb(102, 100, 100);
        border-width: 1px;
        cursor: default;
    }

    .modal-content {
        padding: 1.5rem 1.5rem;
        gap: .5rem;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow: auto;
    }
    .modal-content-title {
        font-weight: 550;
        line-height: normal;
        font-size: 1.25rem;
    }

    .specialty-section {
        overflow: unset;
        height: min-content;
    }
}