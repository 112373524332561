#GenericPageHeader{
  background-color: white;
}
#Timesheet{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  height: 100%;

  .container-style {
      overflow:auto;
  }

  .left-pane-container{
      height:100%;
      overflow:auto;
  }

  .contracts-dropdown {
      height: 34px;
      width: 100%;
      font-size: 1rem;
      padding: 2px 2px 2px 5px;
      border-radius: 5px;
      border: 1px solid #797979;
      background-color: #ffffff;
      box-sizing: border-box;
      font-family: "Barlow-Medium", "Barlow Medium", "Barlow", sans-serif;
      font-weight: 600;
  }

  .timesheets-contract-container {
      display: flex;
      height:100%;
      flex-direction: column;
      flex-grow: 1;
      overflow: auto;
      background-color: #fff;
      gap: 1rem;
  }

  .return-to-results-container {
    display: flex;
    font-weight: bold;
    padding: 5px;
    gap: 5px;
  }

  .mobile-page-header
  {
    font-weight: bolder;
    border-top: 1px solid  #797979;
    border-bottom: 1px solid  #797979;
    font-size: 1.2rem;
    padding: .75rem;
  }

  .p-calendar{
    width: 100% !important;
  }
}