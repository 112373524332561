.facilityInfo {
    margin: 1rem 2rem 0 2rem;
    display: flex;
    flex-wrap: wrap;
}
.facilityInfoItem {
    flex: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.3rem;
    padding-bottom: 0.8rem;
}
.mapContainer {
    height: 500px;
}
.map {
    height: 500px;
}
.facility-detail-icon {
    margin: 0;
    width:1.5rem;
}

.facilityImageMobile {
    display: none;
}
.facilityImageContainerMobile {
    display: none;
}
.mapContainerBorder {
    margin: 0 2rem 2rem 2rem;
    border: 1px solid #797979;
}

@media (max-width: 800px) {
    .facilityInfo {
        flex-flow: column;
        margin-left: 1rem;
    }
    .facilityImageMobile {
        display: block;
        position: relative;
        left: unset;
        top: unset;
        transform: unset;
        width: 100vw;
    }
    .facilityImageContainerMobile {
        display: block;
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    .mapContainer {
        height: 250px;
    }
    .map {
        height: 250px;
    }
    .mapContainerBorder {
        margin: 0;
    }
}