#MobileTRSHeader{
    display: flex;
    
    .mobile-header {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        align-items: center;
        padding:1rem;
    }
    
    .mobile-header-login-button {
        font-weight: bold;
        font-size: 20px;
        color: #000b52;
    }

    .development-header {
        flex-grow:1;
        height:100%;
        background-size:contain!important;
        background-image: url('/assets/DEV.gif');
        background-repeat:repeat;
        background-size:auto;
    }

    .test-header {
        flex-grow:1;
        height:100%;
        background-size:contain!important;
        background-image: url('/assets/TEST.gif');
        background-repeat:repeat;
        background-size:auto;
    }
}