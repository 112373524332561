#GenericDashboardJobContainer, #GenericDashboardJobSubmissionContainer, #GenericDashboardOnboardingContainer, #GenericDashboardAssignmentContainer {
    display: flex;
    flex-direction: column;
    font-family: "Barlow SemiBold", "Barlow", sans-serif;
    .container-header{
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-bottom: 1px solid #797979;
        border-top: 1px solid #797979;
        background-color: #226212;    
        font-size: 22px;
        font-weight: 600;
        color: white;
        padding:.75rem 1.5rem;
    }

    .onboarding-container-body, .assignment-container-body{
        padding:.5rem;
    }
    .assignment-container-body {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .container-body{
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        background-color: #f2f2f2;
        border-top: none;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        font-size: 18px;
        padding: 2rem 2rem;
    }

    .dashboard-job-count {
        font-style: italic;
    }

    .view-all-offers-btn {
        cursor: pointer;
        display: flex;
        background-color: #040b50;
        color: white;
        border-radius: 5px;
        // padding: 0.5rem 4rem;
        height: 2.65rem;
        width: 20.75rem;
        gap: 0.5rem;
        align-items: center;
        font-size: 22px;
        font-weight: 600;
        justify-content: center;
        align-self: center;
        text-wrap: nowrap;
    }

    @media(min-width: 1250px) {
        .container-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border: 1px solid #797979;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            background-color: #135786;    
            font-size: 22px;
            font-weight: 600;
            color: white;
            padding:.75rem 1.5rem;
        }
        

        .container-body{
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            background-color: white;
            border: 1px solid #797979;
            border-top: none;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            font-size: 20px;
            font-weight: 600;
            padding: 2rem 2rem;
        }

        .onboarding-container-body{
            padding:unset;
        }

        .view-all-offers-btn {
            align-self: end;
        }
    }
}