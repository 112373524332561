#EDocumentsAndSignatures{
    overflow: auto;
    .bold{
        font-weight: 600;
    }
    .clickable {
        cursor: pointer;
    }
    .page-container{
        padding: 2rem 16rem 2rem 8rem;
    }
    .sub-title{
        color: #000b57;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 20px;
        font-weight:600;
        padding-bottom: 1rem;
    }

    .pdf-icon{
        max-width: 3rem;
        max-height: 3rem;
    }

    .all-documents-container{
        border: 1px solid #000b57;
        border-radius: 10px;
        background-color: #f2f2f2;
        margin-left: 3rem;
    }

    .document-container{
        display: flex;
        flex-direction: row;
        padding:1rem 1.5rem;
        border-top: 1px solid #797979;
        align-items: center;
        gap: 1rem;
    }


    .document-container:first-child{
        display: flex;
        flex-direction: row;
        padding:1rem 1.5rem;
        border-top: unset;
        align-items: center;
    }

    
    .signable-document-container {
        display: flex;
        flex-direction: row;
        padding:1rem 1.5rem;
        border-top: 1px solid #797979;
        justify-content: space-between;
    }

    .signable-document-container:first-child{
        display: flex;
        flex-direction: row;
        padding:1rem 1.5rem;
        border-top: unset;
        justify-content: space-between;
    }

    .document-display-section {
        padding-top: 2rem;
    }

    .document-display-section:last-child {
        padding-bottom: 2rem;
    }

    .sign-document-button {
        width: max-content;
        height: min-content;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        color: rgba(255, 255, 255, 0.99);
        box-sizing: border-box;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        background-color: rgba(4, 11, 80, 0.99);
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #040b50;
        cursor: pointer;
        align-self: center;
        min-width: max-content;
    }

    @media (max-width: 1249px) {

        background-color: #f2f2f2;

        .page-container {
            padding: 0rem 1rem;

        }

        .mobile-sub-title {
            color: #000b57;
            background-color: rgba(210, 220, 243, 0.99);
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            text-align: center;
            padding: 1rem 0.25rem;
            border-bottom: 1px solid  #797979;
            font-weight: 700;
            font-size: 18px;
        }

        .mobile-document-container{
            border: 1px solid  #797979;
            border-radius: 10px;
            width:100%;
        }

        .sub-title {
            color: #fff;
            background-color:  #135786;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            text-align: center;
            // padding-top: 1rem;
            font-weight: 700;
            padding: 1rem 1rem;
        }

        .all-documents-container {
            margin-left: unset;
            background-color: #f2f2f2;
            border-top-left-radius: unset;
            border-top-right-radius: unset;
        }

        .document-container{
            background-color: #fff;
            flex-direction: column;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            border-top: unset;
        }
    
        .document-container:first-child{
            flex-direction: column;
        }

        .document-container:last-child{
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        

        .signable-document-container:first-child{
            flex-direction: column;
        }
        .signable-document-container:last-child{
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        .signable-document-container {
            background-color: #fff;
            flex-direction: column;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            border-top: unset;

        }

        .contract-details-container{
            display: flex;
            flex-direction: column;
            padding: 1rem;
            gap: 1rem;
        }

    }
}