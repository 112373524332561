
.flip-container.added .flipper-back {
    display: flex;
}
.flip-container.added .flipper-front {
    display: none;
}

.flipper {
    width: 100%;
}

.flipper-front,
.flipper-back {
    backface-visibility: hidden;
    left: 0;
}

.flipper-back {
    justify-content: center;
    gap: 1.5em;
    padding: 0.5em;
    border-radius: 5px;
    border: 1px solid #040b50;
    box-sizing: border-box;
    font-family: "Barlow SemiBold", "Barlow", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    line-height: normal;
    display: none;
}

.removed {
    background-color: rgba(255, 255, 255, 0.99);
}

.flipper-front {
    z-index: 2;
}

.favorite-button {
    width: 100%;
    padding: 0.5em;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.99);
    box-sizing: border-box;
    font-family: "Barlow SemiBold", "Barlow", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: rgba(4, 11, 80, 0.99);
    text-align: left;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5em;
    border: 1px solid #040b50;
}

.favorite-button-img {
    height: 1em;
}

@media (max-width: 800px) {
    .flip-container {
        width: 100%;
        max-width: 20rem;
    }

    .favorite-button {
        font-size: 1rem;
    }

    .flipper-back {
        font-size: 1rem;
    }
}
