#profile-employment-history {
    .emp-history-ul{
        text-align: left;
        text-wrap: nowrap;
        margin-top: .25rem;
    }
    .emp-history-container {
        text-align: left;
        width: 100%;
    }
    .employment-history-type {
        padding-bottom: 20px;
    }
    .employment-history-type-container {
        text-align: left;
        width: 100%;
    }
    .employment-history-facility {
        text-align: left;
        width: 100%;
    }
    .employment-history-dates {
        text-align: left;
        width: 100%;
    }
    .emp-history-mobile-other{
        padding-top:15px;
    }
}