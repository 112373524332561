#GenericPageHeader {
    font: "Barlow Medium", "Barlow", "sans-serif";
    height: 4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(245, 252, 253);
    font-weight: bold;
    font-size: 1.5em;
    border-top: 0.0625rem solid #797979;
    border-bottom: 0.0625rem solid #797979;
    padding: 1.75rem;

    .refer-a-friend-header-container{
        display: flex;
        align-items: center;
        gap: .5rem;
        font-weight: 500;
        font-size: 1.25rem;
        margin-right: 2rem;
        cursor: pointer;
        img {
            height: 57px;
        }
    }
    .link-container {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        cursor: pointer;
    }

    .app-icon {
        height: 2rem;
    }

    .header-spacer {
        flex-grow: 1;
    }

    .title-text {
        flex: 0 1 auto;
    }

    .start-link {
        font-size: 20px;
        font-weight: normal;
    }

    .link-container:hover {
        text-decoration: underline;
    }
}