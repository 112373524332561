#ForgotPasswordModal {
    width: 80%;
    padding: 10px 10px 40px 10px;
    @media(max-width: 800px){
        width: 100%;    
        padding: 0px 10px;
        .forgot-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
        }
        .forgot-form {
            font-weight: 600;
            font-size: 1.2rem;
            font-family: "Barlow SemiBold", "Barlow", sans-serif;
        }
        .forgot-footer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap:2rem;
            padding-top:2rem;
        }
        .forgot-footer button {
            height: 40px;
            justify-content: center;
            padding: 0.5rem;
            font-family: "Barlow SemiBold", "Barlow", sans-serif;
            font-size: 18px;
            font-weight: 600;
            border-radius: 5px;
            margin-top: 1rem;
        }
        .forgot-reset {
            color: white;
            background-color: #000b57;
            border: none;
        }
        .forgot-cancel {
            background-color: white;
            color: #000b57;
            border:1px solid #000b57!important;
        }
        .forgot-close {
            background-color: white;
            color: #000b57;
            border:1px solid #000b57!important;
        }
        #emailaddress {
            margin-top:8px;
        }
        .forgot-input {
            border-radius: 10px;
            border: 1px solid #000b57;
            background-color: rgba(242, 242, 242, 0.99);
            padding: 1em;
        }
    }
    
    .forgot-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    .forgot-form {
        width: 100%;
        font-weight: 600;
        font-size: 1.2rem;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
    }

    .forgot-input{
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }
    .forgot-footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap:2rem;
        padding-top:2rem;
        width: 100%;
    }
    .forgot-footer button {
        width: 50%;
        height: 40px;
        justify-content: center;
        padding: 0.5rem;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 18px;
        font-weight: 600;
        border-radius: 5px;
        margin-top: 1rem;
    }
    .forgot-reset {
        color: white;
        background-color: #000b57;
        border: none;
    }
    .forgot-cancel {
        background-color: white;
        color: #000b57;
        border:1px solid #000b57!important;
    }
    .forgot-close {
        background-color: white;
        color: #000b57;
        border:1px solid #000b57!important;
    }
    


    label {
        display: block;
    }
    input {
        width: 100%;
        height: 2rem;
        border: 1px solid black;
    }
}