#TimeOffRow{
    .dateRow{
        display: flex;
        justify-content: space-between;
        gap: 4%;
        flex-wrap: wrap;
    }
    .dateRowItem{
        display: flex;
        flex-direction: column;
        flex: 1 1 10em;
        gap: 0.5em;
    }
    .hidden{
        visibility: hidden;
    }
    .toLabelRow{
        display: flex;
        justify-content: space-between;
    }
    .dateRow label{
        font-size: 1rem;
    }

}


