#StartApplicationModal {
    .start-application-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: fit-content;
        margin: 5% auto;
        background-color: white;
        border: none;
        z-index: 98;
        min-height: 50%;
        max-width: 80vw;
        border-radius: 10px;
        overflow: hidden;
    }
    .start-application-header {
        font-weight: 600;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #d2dcf3;
        padding: 1.2rem;
        color: #040b50;
        font-size: 1.3rem;
        text-align: center;
        height: 10%;
    }
    .start-application-body-header {
        margin-bottom: 1rem;
    }
    .start-application-body {
        display: flex;
        flex-direction: row;
        max-height: 70dvh;
    }
    .start-application-job-details {
        padding: 0.5em 2em;
        display: flex;
        gap: 1rem;
        flex-direction: column;
    }

    .mobile-details,
    .desktop-only-details {
        display: flex;
        gap: 1rem;
        flex-direction: column;
    }

    .start-application-timeoff-requests label {
        font-size: 1.2rem;
        font-weight: 650;
        color: #000b57;
        width: fit-content;
    }

    .start-application-timeoff-requests textarea {
        width: 100%;
        resize: none;
        flex: 1;
        min-height: 3em;
        font-family: "Barlow", sans-serif;
        font-size: 1rem;
        padding: 0.5em;
    }

    .start-application-timeoff-requests textarea::placeholder {
        font-style: italic;
    }

    #anyOtherRequests {
        min-height: 7em;
    }

    .start-application-timeoff-requests {
        display: flex;
        flex-direction: column;
        background-color: #f2f2f2;
        overflow: hidden;
    }

    .timeoff-scroll-box {
        padding: 1em 2em;
        overflow-y: scroll;
    }

    .timeoff-flex-box {
        display: flex;
        flex-direction: column;
        gap: 1em;
    }

    .start-application-buttons {
        padding: 0.5em 2em;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        scrollbar-gutter: stable;
    }
    .start-application-continue,
    .start-application-cancel {
        width: 45%;
        padding: 0.5em;
        border-radius: 5px;
        border: 1px solid #000b57;
        box-sizing: border-box;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: normal;
        justify-self: end;
    }

    .newDateButton {
        display: flex;
        align-self: flex-end;
        align-items: center;
        justify-content: center;
        gap: 1em;
        width: 40%;
        min-width: 8rem;
        padding: 0.1em;
        border-radius: 5px;
        border: 1px solid #000b57;
        box-sizing: border-box;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: normal;
        color: #000b57;
        background-color: #ffffff;
    }

    .plusSize {
        font-size: x-large;
        vertical-align: middle;
        margin-bottom: .1em;
    }


    .start-application-cancel {
        color: #000b57;
        background-color: #ffffff;
    }
    .start-application-continue {
        background-color: #000b57;
        color: #ffffff;
    }
    .start-application-body-header .jobMainHeaderTitle,
    .start-application-body-header .jobMainHeaderSeparator,
    .start-application-body-header .jobMainHeaderSubtitle {
        font-weight: 700;
        font-size: 1.5rem;
        margin-bottom: 0;
    }
    .start-application-body-header .jobMainHeaderTitle {
        display: block;
    }
    .start-application-modal-background {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.75);
        border: none;
        z-index: 97;
    }
    .item-500 {
        font-weight: 500;
    }

    .error-image {
        height:1rem;
        width:1rem;
    }
    .time-off-error-msg {
        color: #e81111;
        height: 1.2rem;
        font-weight: 600;
        font-family: Barlow SemiBold, Barlow, sans-serif;
    }
    .req-time-off-button-active {
        background-color: rgb(230, 169, 47);
    }
    .req-time-off-yes-button {
        padding:.5em;
        cursor: pointer;
        display:flex;
        justify-content: center;
        flex-grow:1;
        border-top-left-radius: .5em;
        border-bottom-left-radius: .5em;
        border: 1px solid;
    }
    .req-time-off-no-button {
        padding:.5em;
        cursor: pointer;
        display:flex;
        justify-content: center;
        flex-grow:1;
        border-top-right-radius: .5em;
        border-bottom-right-radius: .5em;
        border: 1px solid;
    }
    .req-time-off-label-container {
        display: flex;
        flex-direction: row;
    }
    .req-time-off-yes-no-container {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        margin-left: 1em;
    }

    @media (max-width: 800px) {
        .start-application-container {
            width: 100vw;
            height: 100dvh;
            border-radius: 0px;
            margin: 0px;
            max-width: none;
        }

        .start-application-header {
            border-radius: 0px;
            width: 100%;
            padding: 0;
            font-size: 1rem;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        .start-application-body-header {
            margin-bottom: 0;
        }

        .start-application-body-header .jobMainHeaderTitle,
        .start-application-body-header .jobMainHeaderSeparator,
        .start-application-body-header .jobMainHeaderSubtitle {
            font-size: 1rem;
            margin-bottom: 0;
        }

        .start-application-body {
            flex-direction: column;
            max-height:90%;
        }

        .start-application-job-details {
            padding: 1rem;
            border-bottom: 1px solid #797979;
        }

        .start-application-job-details h3 {
            font-size: 1rem;
            font-weight: 500;
        }

        .start-application-job-details img {
            display: none;
        }

        .mobile-details {
            border-radius: 10px;
            border: 1px solid #040b50;
            background-color: rgba(245, 252, 253, 0.99);
            padding: 0.5rem;
            gap: 0.5rem;
        }

        .desktop-only-details {
            display: none;
        }

        .timeoff-scroll-box {
            padding: 1rem;
        }

        .start-application-buttons {
            border-top: 1px solid #797979;
        }

        .x-button {
            display: unset;
            height: 1.7em;
        }
    }
}
