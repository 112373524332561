.user-menu-modal-background {
    position: absolute;
    top: 4.5rem;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    border: none;
    z-index: 99;
}

.user-menu-items-container {
    display: flex;
    position: fixed;
    flex-direction: column;
    background-color: white;
    border-radius: .7rem;
    border: .07rem solid #040b50;
    padding: .75rem 1rem;
    font-family: "Barlow SemiBold", "Barlow", sans-serif;
    font-weight: 600;
    color: #283c98;
    gap: .7rem;
    top: 4.5rem;
    right:4rem;
    z-index: 2;
    
}

.single-link-container{
    color: #040b50;
    text-decoration: none;
}

.single-link-container:hover {
    text-decoration: underline;
    cursor: pointer;
}


@media (max-width: 1249px) {
    .user-menu-modal-background {
        top: 0;
    }

    .user-menu-items-container {
        right:1.5rem;
        font-weight: bold;
        gap:1rem;
        top: 1;
        height:75vh;
        max-height: min-content;
        overflow:auto;
    }
}