#SubmitStatement {
    display:flex;
    padding-top: 1.2rem;

    .submit-statement-container{        
        display: flex;
        align-items: flex-start;
        justify-content: center;
        font-family: "Barlow Medium", "Barlow", sans-serif;
        font-size: 18px;
        font-weight:500;
        color:#040b50;
        flex-wrap: wrap;
        margin-top: 2rem;
        flex-direction: column;
        width: 100%;
    }

    .submit-stament-sub-container{
        display: flex;
        align-items: center;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
    .submit-statement-text {
        font-weight: 500;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 1.1rem;
        text-align: left;
        font-style: italic;
    }

    .submit-statement-button-container{
        align-self: center;
    }

    .submit-statement-button{
        justify-content: center;
        padding:.75rem;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-weight: 600;
        color:white;
        background-color: #000b57;
        border:none;
        border-radius: 5px; 
        text-align: center;   
        margin-top: 1.5rem;
        font-size: 1.25rem;
        width: 232px;
        cursor:pointer;
    }

    @media (min-width: 801px) {
        .spacer-div {
            flex: 1 1 70%;
        }

        .submit-statement-container{ 
            flex-direction: row;       
            align-items: flex-end;
            flex-wrap: nowrap;
        }

        .submit-stament-sub-container{
            flex: 1 0 30%;
            min-width: 248px;
        }

        .submit-statement-button-container{
            align-self: start;
        }
    }
}