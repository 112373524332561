#CredentialsDropbox {
    overflow: auto;

    .upload-modal {
        position: fixed;
        width: 70%;
        border: 1px solid  #797979;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #f2f2f2;
        border-radius: 10px;
        
        label {
            font-weight: 600;
        }

        .credentials-upload-button {
            align-self: end;
        }
        
    }
    
    .upload-modal-loading {
        position: fixed;
        width: 70%;
        //height: 90%;
        border: 1px solid  #797979;
        top: 05%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #f2f2f2;
        border-radius:10px;
        
        label {
            font-weight: 600;
        }

        .credentials-upload-button {
            align-self: end;
        }
        
    }

   
    .modal-container-background{
        position: fixed;
        isolation: isolate;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.75);
        border: none;
        z-index: 99;
    }
    .modal-content {
        display: flex;
        flex-direction: row;
        height: 80%
    }

    .modal-inputs{
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        padding: 1.25rem;
        gap: .25rem;
    }

    .modal-input-container{
        padding: 0rem 2rem;

        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;    
    }

    .modal-document-preview{
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        background-color: #fff;
        border: 1px solid gray;
        height: 80vh;
        max-height: 80vh;
    }

    .document-display {
        display: flex;
        flex-direction: column;
        height:100%;
        width: 100%;
        border: 1px solid gray;
        overflow: auto;
    }


    .document-preview-filename{
        font-weight: 600;
        text-decoration: underline;
        padding: 1rem;
    }

    .modal-header {
        background-color:  #d2dcf3;
        color:  #040b50;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        padding: 1rem;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        justify-content: space-between;
    }

    .modal-close-button {
        cursor: pointer;
        border-radius: 3px;
        padding: 0rem 0.25rem;
    }

    .doc-label-input{
        padding: .5rem;
    }

    .upload-button-container {
        width: 100%;
        padding-left: 8rem;
        padding-top: 1rem;
    }
    .page-container{
        padding: 1rem 16rem 2rem 8rem;
        display:flex;
        flex-direction: column;
        gap:3rem;
    }
    .upload-document-container{
        gap:.75rem;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
    .sub-title{
        color: #000b57;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 20px;
        font-weight:600;
        padding-bottom: 1rem;
    }
    .upload-button {
        width: max-content;
        height: min-content;
        padding: 0.5rem .5rem;
        border-radius: 5px;
        color: rgba(255, 255, 255, 0.99);
        box-sizing: border-box;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        background-color: rgba(4, 11, 80, 0.99);
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;
        border: 1px solid #040b50;
        cursor: pointer;
    }
    
    .uploaded-documents {
        padding: 1rem;
        border: 1px solid rgba(4, 11, 80, 0.99);
        padding-bottom: 5rem;
        border-radius: 5px;
    }
    .modal-button-container {
        display: flex;
        gap:1.5rem;
        padding-bottom: 4%;
        justify-content: center;
        flex-wrap: wrap;
        background-color:white;
        border-top:1px solid gray;
        .modal-change-button {
            width: max-content;
            height: min-content;
            padding: 0.5rem .5rem;
            border-radius: 5px;
            color: #040b50;
            box-sizing: border-box;
            font-family: "Barlow SemiBold", "Barlow", sans-serif;
            font-size: 1.2rem;
            font-weight: 600;
            background-color: white;
            line-height: normal;
            display: flex;
            align-items: center;
            gap: 1rem;
            justify-content: center;
            border: 1px solid #040b50;
            cursor: pointer;
            min-width: 10rem;
            font-size: 1rem;
        }
        .modal-upload-button {
            width: max-content;
            height: min-content;
            padding: 0.5rem .5rem;
            border-radius: 5px;
            color: rgba(255, 255, 255, 0.99);
            box-sizing: border-box;
            font-family: "Barlow SemiBold", "Barlow", sans-serif;
            font-size: 1.2rem;
            font-weight: 600;
            background-color: rgba(4, 11, 80, 0.99);
            line-height: normal;
            display: flex;
            align-items: center;
            gap: 1rem;
            justify-content: center;
            border: 1px solid #040b50;
            cursor: pointer;
            min-width: 10rem;
            font-size: 1rem;
        }
        .modal-upload-button:disabled{
            background-color: #9c9eb2;
            color: #cfcfcf;
            border-width: 1px;
            cursor:default;
        }
    }

    

    @media (max-width: 1250px) {
        background-color: #f2f2f2;
        overflow: unset;

        .page-container{ 
            padding: 0rem 1rem;
            gap:unset;
            overflow: auto;
            height: 75vh;
        }
        .upload-button-container {
            padding-left:2rem;
            padding-bottom: 2rem;
        }
        .document-preview{
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            background-color: #f2f2f2;
            border: 1px solid gray;
            height: 62vh;
            max-height: 62vh;
        }

        .upload-modal {
            position: fixed;
            width: 100%;
            height: 100%;
            border: 1px solid  #797979;
            background-color: #fff;
            border-radius: unset;
            
            label {
                font-weight: 600;
                align-self: center;
            }
    
            .credentials-upload-button {
                align-self: end;
            }
        }

        .modal-header {
            border-top-left-radius: unset;
            border-top-right-radius: unset;
        }
        
        .modal-content {
            flex-direction: column;
        }

        .modal-button-container {
            width: 100%;
            flex-direction: column;
            align-content: center;
            padding-top: 1rem;
            position: fixed;
            bottom: 0;
            align-self: center;
        }

        .modal-inputs {
            padding: 1rem;
            flex-direction: row;
            justify-content: space-between;
        }

        .trash-can {
            width: 3rem;
            padding-left: 0.5rem;
        }
        .uploaded-documents {
            padding: 1rem;
            border: unset;
            padding-bottom: 5rem;
            padding-top: unset;
            gap: 1rem;
            display: flex;
            flex-direction: column;
        }
    
        .modal-button-container {
            .modal-upload-button {
                width: 80%;
            }
            .modal-change-button {
                width: 80%;
            }    
        }
        
        .doc-label-input{
            flex-grow: 1;
        }
    }

    @media (min-width: 800px) and (max-width: 1249px) {

        .page-container {
            height: 83vh;
        }
        .document-preview {
            height: 72vh;
            max-height: 72vh;
        }
    }
    
    
}

