#JobSearchPage{
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;

  .pageContainer {
    display: flex;
    flex-direction: column;
    z-index: 0;
    overflow-x:auto;
    border-top: 1px solid #797979;
  }

  .mobile-filter{
    display: flex;
    flex-direction: column;
  }
}