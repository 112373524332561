#SubmittedJobDetails {

    .requests-header {
        font-family: "Barlow Bold", "Barlow", sans-serif;
        font-weight: 700;
        color: #000b57;
        text-align: left;
        line-height: 20px;
        background-color: #d2dcf3;
        padding: 1rem;
        font-size: 1.25rem;
    }

    .contact-recruiter-footer {
        display: flex;
        gap: .5rem;
        background-color:  #dcdcdc;
        width: 100%;
        min-height: 3rem;
        align-content: center;
        align-items: center;
        font-size: 1.25rem;
        font-weight: 500;
        padding-left: 1rem;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .contact-text {
        font-weight: 700;
    }

    .values{
        padding: 1rem;
    }

    .value-header {
        font-family: "Barlow Bold", "Barlow", sans-serif;
        font-weight: 500;
        font-size: 1.125rem;
    }

    .value {
        font-family: "Barlow Bold", "Barlow", sans-serif;
        font-weight: 700;
        padding-bottom: .5rem;
        font-size: 1.125rem;
    }

    @media (min-width: 800px) {
        background-color: #d2dcf3;
        margin: 1rem;
        border-radius: 10px;
       
        .requests-header {
            background-color: unset;
        }

        .values {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }

        #startDate {
            grid-column: 1/3;
            grid-row: 1/2;
        }
        #bestTime {
            grid-column: 3/3;
            grid-row: 1/2;
        }

        #notes {
            grid-column: 1/3;
            grid-row: 2/2;
        }

        #timeOff {
            grid-column: 2/3;
            grid-row: 1/2;
        }
    }
}