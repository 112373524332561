.login-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
    width: 750px;
    background-color: white;
    border: none;
    border-radius: 10px;
}

.login-selected{
    border-top-left-radius: none;
}

.signup-selected{
    border-top-right-radius: 0px;
}

.login-modal-background {
    position: fixed;
    isolation: isolate;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    border: none;
    z-index: 99;
}

.login-header{
    padding: 2.5rem;
    display:flex;
    background-color: #d2dcf3;
    height: 4rem;
    width: 100%;
    outline: none;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    color:#000b57;
    font-size: 1.25rem;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid black;
}

.login-header-button, .signup-header-button{
    cursor:pointer;
}


.x-button {
    display: none;
}

body{
    overflow: hidden;
 }

 
.login-selected{
    border-top-left-radius: 0px;
}

.signup-selected{
    border-top-right-radius: 0px;
}

.login-body{
    display:flex;
    flex-direction:column;
    align-items: center;
    width:100%;
    font-weight: 600;
    padding: 2rem 5rem;
    overflow-y: auto;
}

.login-form-header{
    width:85%;
    font-size:20px;
    color:#000b57;
}

.login-form-header-item{
    display: flex;
    width:100%;
}


.selected-form-header-item{
    border-bottom:6px solid #000b57;
}


.login-form-header-text{
    display: flex;
    width: 50%;
    justify-content: space-around;
    padding-bottom: 8px;
    font-size: 18px;
    font-weight: 800;
}

.invalid-credential-border {
    border-color: red;
}

 
.login-selected{
    border-top-left-radius: 0px;
}

.signup-selected{
    border-top-right-radius: 0px;
}

.login-body{
    display:flex;
    flex-direction:column;
    align-items: center;
    width:100%;
    font-weight: 600;
}

.login-form-header{
    width:100%;
    font-size:20px;
    color:#000b57;
}

.login-form-header-item{
    display: flex;
    width:100%;
}


.selected-form-header-item{
    border-bottom:6px solid #000b57;
}

.invalid-credential-border {
    border-color: red;
}

.login-body{
    width:100%;
    font-weight: 600;
    padding: 2rem 5rem;
}

.login-modal-button{
    width:20rem;
    justify-content: center;
    padding:.5rem;
    font-family: "Barlow SemiBold", "Barlow", sans-serif;
    font-size:21px;
    font-weight: 600;
    color:white;
    background-color: #000b57;
    border:none;
    border-radius: 5px;

}

.login-modal-button:disabled{
    color:#cfcfcf;
    background-color: #9c9eb2;
}


@media(max-width: 800px){
 .login-container{
    width: 100vw;
    height: 100dvh;
    border-radius: 0px;
    margin: 0px;
    max-width: none;
 }   

 .login-header{
    padding: 2em 1em;
    margin-bottom: 4vh;
    text-align: center;
    justify-content: space-between;    
 }


 .login-body {
    padding: 1rem;
    flex:1;
    display:flex;
    flex-direction: column;
    justify-content: start;
    overflow-y: hidden;
 }
 

 .x-button {
    display: unset;
    height: 1.25em!important;
 }

    .login-modal-button{
    width: 80%;
}
}
