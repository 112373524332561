 #TimesheetShift{
 
    .TimesheetShift{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 1rem;
        background-color: #f5fcfd;
        border: 1px solid #040b50;
        border-radius: 10px;
        padding: 1rem;
        .shift-item{
            width:25%;
            color: #040b50;
            font-size: 20px;
            .item-value {
                font-weight:550;
                display:block;
            }
        }
    }

    .TimesheetShiftEditContainer{
        display: flex;
        flex-direction: column;  
        padding-left:0; 
        flex-grow: 1;
        overflow: auto;
    }
    
    .TimesheetShiftEdit{ 
        padding:1rem;
        padding-bottom: 2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; 
        row-gap: 1rem;
        gap:.75rem;
         
        background-color: #f2f2f2;
        border: 1px solid #797979;
        border-radius: 10px; 
         
        .shift-item{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap; 
            width:30%;
            row-gap: .5rem;
            color: #040b50;
            font-size: 20px;  
             
            .item-value {
                font-family: "Barlow", "sans-serif";
                font-weight:550; 
                font-size: 1.1rem;
                padding:0.5rem 0.75rem;
                color: #040b50;
            }
        } 
        
        #shift-lunch{
            width:5.0rem;
        }
        .shift-lunch-container{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap; 
            gap:.8rem;
        }

        #shift-date{
            font-weight:bold !important;
            color: #040b50 !important;
        }
    }  
    
    .shift-item-times-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;  
        gap: 2.8rem;
        
    }
    .edit-buttons-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
        justify-content: space-between;
        padding:1.0rem;         
        width: 100%;  
    }
    
    .saved-record-container{
        background-color: #f1fafc;
        border-radius: 7px;
        border:1px solid #000b57;
        height: 100%;
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        padding: 2rem;
        font-size: 20px;
        margin:1px;
    }
    .save-record-button{
        width: 11rem;
        height: 2.5rem;
        border-radius: 5px;
        font-size: 1.1rem;
        font-weight: 600;
        border-color: #040b50;
        color:white;
        background-color: #040b50 ;
        border-style: solid;
        cursor: pointer;
        //justify-content: right;
    }
   .save-record-button:disabled{
    background-color: #9c9eb2;
    color: #cfcfcf;
    border-width: 1px;
    cursor:default;
}
.delete-record-button{
    width: 11rem;
    height: 2.5rem;
    padding-top: 2px;
    border-radius: 5px;
    font-size: 1.1rem;
    font-weight: 600;
    border-color: #fa0000;
    color: #fa0000;
    background-color: #ffffff;
    border-style: solid;
    cursor: pointer;
    min-width:unset;
}

.delete-record-text {
    min-width: fit-content;
}

.delete-record-button:disabled{
    background-color: #ffffff;
    color: #cfcfcf;
    border-color: rgb(102, 100, 100);
    border-width: 1px;
    cursor:default;
}


    @media (max-width: 1250px) {
    
        .TimesheetShift{ 
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            row-gap: 1rem;
            background-color: #f5fcfd;
            border: 1px solid #040b50;
            border-radius: 10px;
            padding: 1rem;
    
            .shift-item{
                width:unset;
                color: #040b50;
                font-size: 20px;

                .item-value {
                    font-weight:550;
                    display:inline;
                }
            }
             
        }
          
        .TimesheetShiftEditContainer{
            display: flex;
            flex-direction: column;  
            padding:1rem; 
            padding-left:0;
            flex-grow: 1;
            overflow: auto;
        }
    
        .TimesheetShiftEdit{
            padding: 1rem;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            row-gap: 1rem;
            background-color:white;
            border: 1px solid #797979;
            border-radius: 10px;
            
            .shift-item{
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                width:unset;
                background-color: white;
                color: #040b50;
                font-weight: 560;
                .item-value {
                    font-weight:550;
                }
            }    
        }

        .edit-buttons-container{
            padding: unset;
        }
       
    }
}