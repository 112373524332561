.jobSearchFilters .topContainer {
    width: 100%;
    height: min-content;
    border: 1px solid rgba(121, 121, 121, 1);
    border-radius: 10px;
    padding: 0rem 1rem 2rem 1rem;
    background-color: rgba(242, 242, 242, 0.99);
}

.searchButtonDiv {
    display: flex;
    height: 100%;
    justify-content: flex-end;
}

li:has(#location-id--99){
    background-color: rgba(240, 179, 35, 0.44);
}

.p-multiselect-items:has(#location-id--99) {
    padding: 0;
}

@media (max-width: 800px) {
    .columnStyle {
        width: 100%;
    }

    .scrolable {
        overflow-x: clip;
        overflow-y: scroll;
        border: 1px solid #797979;
        border-radius: 10px;
        padding: 1em;
        margin-top: 1em;
        background-color: rgba(242, 242, 242, 0.9921568627450981);
    }

    .searchButtonDiv {
        justify-content: center;
        height: fit-content;
    }

    /* .jobSearchFilters {
        height: 100%;
    } */

    .x-button {
        height: 2em;
    }

    .filterTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 3em;
        padding: 0;
        margin: 0;
        align-items: center;
    }

    .jobSearchFilters .topContainer {
        height: calc(100% - 3em);
        border: none;
        top: 0;
        left: 0;
        padding: 0;
        flex-direction: column;
        justify-content: start;
        display: flex;
        background-color: unset;
        margin: 0rem;
    }
}
