#SelectButtonWrapper{
    @font-face {
        font-family: "Barlow";
        font-weight: 600;
        src: url(../fonts/Barlow-SemiBold.ttf) format("truetype"), local("Barlow");
    }
    
    .p-selectbutton {
        border-radius: 1rem;
        border: 1px solid #797979;
        overflow: clip;
        width: auto;
        max-width: 100%;
        height: 4rem;
        display: flex;
        justify-content: space-between;
    }
    
    .p-button:nth-child(2) {
        border-left: 0.01rem solid #797979;
        border-right: 0.01rem solid #797979;
    }
    
    .p-button ~ .p-button:nth-child(4) {
        border-left: 0.01rem solid #797979;
    }
    
    .preferred-shift-select-button > .p-button:nth-child(1) {
        border-radius: 1rem, 0, 0, 1rem;
    }
    .preferred-shift-select-button > .p-button:nth-child(2) {
        border-radius: 0, 0, 0, 0;
    }
    .preferred-shift-select-button > .p-button:nth-child(3) {
        border-radius: 0, 1rem, 1rem, 0;
    }
    
    .shifts-per-week-select-button > .p-button:nth-child(1) {
        border-radius: 1rem, 0, 0, 1rem;
    }
    .shifts-per-week-select-button > .p-button:nth-child(2) {
        border-radius: 0, 0, 0, 0;
    }
    .shifts-per-week-select-button > .p-button:nth-child(3) {
        border-radius: 0, 0, 0, 0;
    }
    .shifts-per-week-select-button > .p-button:nth-child(4) {
        border-radius: 0, 1rem, 1rem, 0;
    }
    .shifts-per-week-select-button > .p-button {
        font-weight: 600;
        font-size: 1rem;
    }
    .p-button {
        background-color: white;
        color: black;
        width: 100%;
        line-height: 1;
        height: 100%;
        justify-content: center;
        font-family: "Barlow", sans-serif;
        padding: 0;
        text-wrap: balance;
        border-width: 0;
        border-style: solid;
        border-color: #f3f3f3;
        font-size: .75rem;
    }
    
    .p-button img {
        width: 23px;
        height: 23px;
        margin: auto;
        margin-bottom: 0.5rem;
    }
    
    .p-button:nth-child(2) img {
        transform: rotate(90deg);
    }
    
    .p-buttonset {
        border-radius: 1rem;
    }
    
    .p-button:focus {
        box-shadow: none;
    }
    
    .p-button.p-highlight {
        background-color: #f0b323;
    }
    
    .optionText {
        display: block;
    }
}
