#FilterBar{
  display: flex;
  flex-direction: column;
  .search-filter-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .8rem 2rem;
    background-color: #f5fcfd;
    border-bottom: 1px solid #797979;
  }

  .allFiltersButton {
    cursor: pointer;
    display: flex;
    background-color: #040b50;
    color: white;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    align-items: center;
    font-weight: normal;
    justify-content: center;
    width: fit-content;
  }

  .filterSearchIcon {
    width: 17px;
    height: 17px;
  }

  .mobileFiltersContainer {
    display: none;
    // position: absolute;
    z-index: 1;
  }
  .desktopFiltersContainer {
    display: flex;
    // position: absolute;
    z-index: 110;
    top: 0;
    left: 0;
    width: 100%;
  }
  .findAJob {
    margin-left: 0.5rem;
    font-weight: bold;
    font-size: 24px;
  }

  .searchButton {
    display: flex;
  }

  .link-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
  }

  .app-icon {
    height: 2rem;
  }

  .header-right-side {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .start-link {
    font-size: 20px;
    font-weight: normal;
    cursor: pointer;
  }

  .start-link:hover {
    text-decoration: underline;
  }

  @media (max-width: 800px) {
    .search-filter-area {
      padding: 1rem;
    }

    .findAJob {
      font-size: 20px;
    }

    .mobileFiltersContainer {
      display: flex;
      align-content: center;
      // position: unset;
    }
    .desktopFiltersContainer {
      display: none;
    }

    body {
      transform: none;
    }
  }
}
