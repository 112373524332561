.job-alt-main-header {
    transition: top 0.5s ease-in-out;
    width: 100%;
    position:absolute;
    top: -100%;
    background-color: white;
    padding-left: 2rem;
    padding-top: 0.6rem;
    border-bottom: 1px solid #797979;
    z-index: 1;
}

.left-justified{
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.right-justified{
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-right: 2rem;
    padding-bottom: 1.5rem;
    max-width: 100%;
    padding: 1rem 0;
    gap: 1.5rem;
}

.job-alt-header-title {
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 0.5rem ;
    display: flex;
    flex-direction: row;
}
 
.detail-icon {
    margin-left: 0.5rem;
    margin-right: 0.75rem;
    width: 1.5rem;
  }

@media (max-width: 800px) {
    .job-alt-main-header{
        transition: top 0.5s ease-in-out;
        width: 100%;
        position: absolute;
        top: -100%;
        background-color: white;
        padding-left: 2rem;
        padding-top: 0.6rem;
        border-bottom: 1px solid #797979;
        z-index: 100;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .job-alt-header-title {
        font-size: 1.2rem;
        padding-bottom: unset;
    }

    .right-justified {
        flex-direction: column;
        margin-right: 0;
        padding: .5em 0;
        align-items: center;
        gap: 1rem;
    }

}