#LoginForm {

    width:100%;

    .login-modal-field > label {
        display: block;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 18px;
        margin-bottom: 5px;
    }

    .login-modal-field > input {
        height:2.125rem;
        width:20rem;
        padding:.25rem;
        border-radius: 5px;
        font-size: 1.2rem;
    }

    .login-body-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        border:1px solid #000b57;
        border-top-left-radius: 0px;
        border-top-right-radius:10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius:10px;
        height: 35rem;
        margin:auto;
    }
  
    .login-form{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 50%;
        padding-left:2rem;
        align-items: center;
        font-size: 1rem;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        gap:2rem;
        padding-top:3rem; 
        padding-bottom:3rem; 
        padding-left: 0;
        padding-right: 0;
    }
    
    .padded {
        height: 1.2rem;
    }

    .alert {
        height: 1rem;
        width: 1rem;
        vertical-align: middle;
    }

    .login-footer{
        display:flex;
        justify-content:center;
        flex-direction: row;
        width: 100%;
        gap: 3rem;
        font-weight: 600;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        color:#000b57
    }
    .link {
        text-decoration: underline;
        cursor: pointer;
    }
    .login-footer > a:visited{
        color:#000b57
    }

    .login-footer-account{
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }
    .login-footer-account > a:visited{
        color:#000b57
    }

    .invalid-credential-text {
        height: 1.2rem;
        color: #E81111;
    }

    .invalid-credential-border {
        border: 1px red solid;
    }

    .valid-credential-text {
        border:1px solid #000b57;
    }

    @media(max-width:800px){
        height: 100%;

        .login-body-container{
            padding-left: 1rem;
            padding-right: 1rem;
            height: 95%;
            overflow-y: scroll;
            margin: 0px;
        }

        .login-footer{
            gap:none;
        }

        .login-modal-field{
            width: 100%;
        }
        
        .login-modal-field > input {
            width: 100%;
        }

        .login-modal-button{
            width:100%;
        }
        
    }

}