#DesktopTRSHeader{
    display: flex;
    
    .desktop-page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        font-size: 16px;
        font-weight: 400;
        padding: .6rem;
        border-bottom: 1px solid #cacaca;
        flex-grow:1;
    }

    .development-header {
        flex-grow:1;
        height:100%;
        background-size:contain!important;
        background-image: url('/assets/DEV.gif');
        background-repeat:repeat;
        background-size:auto;
    }

    .test-header {
        flex-grow:1;
        height:100%;
        background-size:contain!important;
        background-image: url('/assets/TEST.gif');
        background-repeat:repeat;
        background-size:auto;
    }

    .desktop-page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        font-size: 16px;
        font-weight: 400;
        padding: .6rem;
        border-bottom: 1px solid #cacaca;
        flex-grow:1;
    }

    .trs-logo-image{
        height:3.4rem;
        margin-top:-5px;
    }

    .right-header {
        display: flex;
        gap: 8.125rem;
    }

    .login-button {
        display: flex;
        align-items: center;
        text-decoration-line: none;
        color: #000b57;
        gap: 0.5rem;
        text-underline-offset: 4px;
        cursor: pointer;
    }

    .login-button:hover {
        text-decoration-line: underline;
    }

    .secondaryHeader {
        height: 4rem;
        align-content: center;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 3rem;
        padding-left: 1.5rem;
        background-color: white;
    }

    .secondaryHeader a {
        font-weight: 600;
        color: #000b57;
        padding-top: 0.5rem;
        text-decoration: none;
        height: 100%;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
    }

    .secondaryHeader a:hover {
        color: #ea580c;
        text-decoration-line: underline;
    }

    .header-icon {
        background-color: white;
        border: unset;
        height: fit-content;
        padding-top: 0.5rem;
    }

    .secondary-header-icon-wrapper {
        padding-top: 0.5rem;
    }

    .secondary-header-icon-wrapper svg {
        border-radius: 9999px;
        width: 25px;
        height: 25px;
        padding: 5px;
    }

    .secondary-header-icon-wrapper svg:hover {
        background-color: #e5e7eb;
    }

    .secondary-header-dropdown-wrapper {
        height: 100%;
        flex-wrap: nowrap;
        display: flex;
        align-items: center;
    }

    .right-side-header-items-logged-in {
        display: flex;
        width: 30rem;
        justify-content: space-between;
    }
    .right-side-header-items-logged-in-alt {
        display: flex;
        justify-content: flex-end;
    }

    .right-side-header-items-incomplete-application {
        display: flex;
    }

    @media (max-width: 1250px) {
        .go-to-travelerhub-button {
            display: none;
        }
    }
}