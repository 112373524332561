#PermanentAddress {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    
    .previousNextButtonHeader {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top:1rem;
        justify-content: space-between;
        padding-bottom:1rem;
    }    

    h2 {
        font-family: "Barlow-Bold", "Barlow", sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 0;
        padding-bottom: 0.5rem;
    }

    .form {
        border: 1px solid #040b50;
        background-color: white;
        border-radius: 10px;
        display: flex;
        align-items: flex-start;
        font-size:large;
        flex-direction: column;
        justify-content: space-around;
        padding: 1.5rem 1rem;
        gap: 1em;
    }

    label {
        font-weight: 550;
        font-size: large;
        padding: 0 0 .5rem 0;
    }

    select {
        font-weight: 450;
        font-size: large;
        max-width: 11rem;
        border-radius: 5px;
        padding: 0.25rem;
        background-color: white;
        color: #040b50;
    }

    input {
        font-weight: 450;
        font-size: large;
        width: 100%;
        border-radius: 5px;
        border: 1px solid black;
        padding: 0.25rem;
        color: #040b50;
    }

    .country {
        display:flex;
        flex-direction: column;
        align-content: center;
    }

    .street-address {
        display:flex;
        flex-direction: column;
        align-content: center;
    }

    .city {
        display:flex;
        flex-direction: column;
        align-content: center;
    }

    .state {
        display:flex;
        flex-direction: column;
        align-content: center;
    }

    #state-dropdown{
        border: 1px black solid;
    }

    #country-dropdown{
        border: 1px black solid;
    }

    .zip {
        display:flex;
        flex-direction: column;
        align-content: center;
        font-weight: 550;
        font-size: large;
    }

    #postal-code-input, #zip-code-input{
        width: 7rem;
    }


    @media(min-width: 801px){
        height: 75%;
        .title {
            display: flex;
            flex-direction: column;
            border-bottom: .25em solid #040b50;
            margin-bottom: 1.5em;
        }

        h2 {
            font-family: "Barlow-Bold", "Barlow", sans-serif;
            font-size: 2em;
            margin: 0;
            font-weight: bold;
        }

        .form{
            flex-direction: row;
            height: unset;
            padding: 2rem;
            background-color: rgb(242, 242, 242);
        }

        .previousNextButtonHeader {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-top:1.5rem;
            padding-bottom:1.5rem;
            justify-content: space-between;
        } 
    
    }
}