#ModalErrorMessage {
    .hidden-preload-image {
        display: none;
    }
    .modal {
        position: absolute;
        top: 0;
        left: 0;
        height: 100dvh;
        width: 100dvw;
        background-color: white;
        z-index: 9999;
        border: 1px solid #797979
    }

    .header {
        background-color: #f44336;
        color: white;
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
        padding: 0.7em;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        border: 1px solid #797979;
        border-collapse: collapse;
    }

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2em 1em;
        gap: 1.5em;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
    }
    .message-container {
        display:flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0.5em 1em 1em 1em;
        font-size: 1.2em;
        font-weight: 600;
        border-radius: 10px;
        border: 1px solid #000b57;
        background-color: rgba(242, 242, 242, 0.99);
    }
    .error-message {
        text-align: center;
    }
    .error-image {
        height: 2em;
    }
    .close-button {
        width: 100%;
        max-width: 25em;
        padding: 0.5em;
        border-radius: 5px;
        border: 1px solid #000b57;
        box-sizing: border-box;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-weight: 600;
        font-size: 1.2rem;
        color: #000b57;
        line-height: normal;
        background-color: white;
    }

    @media (min-width: 800px) {
        .background {
            position: absolute;
            top: 0;
            left: 0;
            height: 100dvh;
            width: 100dvw;
            background-color: rgba(0, 0, 0, 0.75);
            z-index: 9999;
            display:flex;
            justify-content: center;
            align-items: center;
        }

        .modal {
            position: relative;
            top: unset;
            left: unset;
            height: unset;
            width: unset;
            border-radius: 10px;
            max-width: 60%;
        }
        .body {
            padding: 1em 3em;
            background-color: rgba(242, 242, 242, 0.99);
        }
        .message-container{
            border: unset;
        }
    }
}