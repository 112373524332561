.SingleBackgroundQuestion:not(:first-of-type){
    padding-top:.5rem;
}
.SingleBackgroundQuestion{

    .unselected{
        background-color: white;
    }
       
    .question-container{
        padding: .5rem;
        border-bottom: 1px #dbdbdb solid;
        padding-bottom: 1.2rem;
    }

    .main-question-container {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Barlow Medium", "Barlow", sans-serif;
        font-size: 18px;
        font-weight:500;
        color:#040b50;
        flex-wrap: wrap;
    }
    
    #question-1, #question-2 {
        border:none;
    }
    
    #question-3{
        border-top: 1px #dbdbdb solid;
        padding-top: 1rem;
    }

    .question{
        padding-bottom: 1rem;
        padding-right: .8rem;
        flex: 1 1 70%;
    }

    .selections{
        flex: 1 0 30%;
        display: flex;
        justify-content: flex-end;
    }

    .yes-no-button-container{
        display: flex;
        flex-direction: row;
        height:100%;
        background-color: white;
        font-size: 20px;
        border-radius: 10px;
        border: 1px grey solid;
        max-width: 254px;
        width: 100%;
    }

    .yes-border{
        border-right:1px grey solid;
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
    }

    .no-border {
        border-left:1px grey solid;
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
    }

    .selection-item{
        width: 50%;
        height:100%;
        padding: .5rem 1.7rem;
        text-align: center;
        cursor:pointer;
    }

    @media (min-width: 801px) {
        .question-container{
            flex-wrap: unset;
            justify-content: space-between;
            gap: 2rem; 
        }

        .main-question-container{
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        .question{
            padding-bottom: unset;
        }

        .selection-item{
            padding: .5rem 3rem;
        }

        .selections{           
            justify-content: start;
        }
    }
}