#Timesheet{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  height: 100%;

  .container-style {
      overflow:auto;
  }

  .left-pane-container{
      height:100%;
      overflow:auto;
  }
 
  .container-tab {
      display: flex;
      flex-direction: row;
      background-color:  #E1E1E1;
  }

  .container-timesheets{    
    display:flex;
    flex-direction: column; 
    padding: 25px 75px;
    background-color: #E1E1E1;
    border: #797979 solid 1px;
    flex-grow: 1;
    overflow: auto;

     
  }
  .content-container{
    display:flex;
    flex-direction:row; 
    background-color: #F2F2F2;
    padding:20px;
    border: #797979 solid 1px; 
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    flex-grow: 1;
    overflow: auto;
  }

.container-content-list{
  font-family:"Barlow Bold", "Barlow", "sans-serif";
  font-size: 18px;   
  color: #283C98;
  font-weight:800px;
  background-color:white;
  padding-left:15px;    
  padding-right:10px;    
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border:1px solid #797979; 
} 
 
.container-content-list-facility{
font-weight:700;
} 

.content-container-right{
border: black 1px solid; 
}

.timesheet-container-header{
background-color: #135786; 
border-radius: 10px;
color: white;
text-align: center;
font-weight: 550;
height: 40px;
padding: 10px;
width: 300px; 
border-bottom-left-radius: 0px;
border-bottom-right-radius: 0px; 
}
 
.container-content-list-selected{
  background-color: #135786;
  color: white;
  margin:0;
  }
 

.timesheet-container {
  display:flex;
  flex-direction: row; 
  background-color: #F2F2F2;
  border:0; 
  flex-grow: 1;
  overflow: auto;
  gap: 1rem;
}
.timesheet-container-left{ 
 // overflow:auto;
  display:flex;
  flex-direction:column;
  }

.timesheet-container-right{
  flex-grow: 1;
  overflow:auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  border:1px solid #797979;   
  border-radius: 10px; 
   
}
 
  @media (min-width: 1250px) {
      /*Desktop overrides*/
    
      /*
    .page-title-mobile{
      display:none;
    }
      */
  


  }

}