#StartEndDates {
    display: flex;
    justify-content: end;
    padding: 1rem 0;
    flex-direction: column;
    align-items: end;

    input {
        font-weight: 450;
        width: 100%;
        border-radius: 5px;
        border: 1px solid black;
        padding: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        font-size: 1rem;
    }

    .p-inputtext {
        width: unset;
    }

    .date-input-container{
        display: flex;
        flex-direction: column;
        padding-left: 0 1rem;
        flex-grow: 1;
    }

    .start-date {
        padding-bottom: 1rem;
    }

    label {
        padding-bottom: .4rem;
    }

    @media (min-width: 801px) {

        .p-inputtext {
            width: 6.8rem;
        }

        .date-input-container{
            flex-grow: 0;            
        }

        flex-direction: row;

        input {
            width: 6rem;
        }

        .start-date {
            padding-bottom: 0;
        }
        
        .spacer-container{
            flex: 1 0 70%;
        }
        
        .content-container{
            flex: 1 0 30%;
            display: flex;
        }

        .follow-up-question-container{
            display: flex;
        }

        .end-date-container {
            padding-left: .5rem;
        }
    }
}

