.p-datepicker{
    font-family: "Barlow", sans-serif;
}

.p-disabled {
    background-color:#F1F1F1!important;  
    color: #999;  
    cursor: default; /* Remove pointer cursor */
}
   
#DatePickerWrapper {
    .form-invalid {
        border: 1px solid red;
    }
    
    .p-inputtext{
        border: 1px solid #797979;
        font-family: "Barlow", sans-serif;
    }

    .p-button {
        background-color: white;
        color: black;
        border: 1px solid #797979;
    }
}