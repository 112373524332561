#SkillsChecklistSummary {
    font-family: "Barlow Medium", "Barlow", sans-serif;
    font-weight: 500;
    color: #040b50;
    display: flex;
    flex-grow: 1;
    overflow: auto;
    flex-direction: column;
    
    .skills-summary-title {
        font-size: 1.5rem;
        font-weight: 600;
        padding-bottom: 1rem;
    }

    .application-page-container {
        padding-top: 3rem;
    }

    .email-info-container {
        display: flex;
        flex-direction: column;
        border: 1px solid black;
        border-radius: 10px;
        background-color: white;

        overflow: hidden;
        text-overflow: ellipsis;
    }

    .email-info-line {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid black;
        padding: 1.25rem;
    }

    .email-info-line {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        border-bottom: 1px solid black;
        padding: 1.25rem;
    }

    .email-info-line:last-child {
        border: 0;
    }

    .email-info-field {
        font-weight: 600;
        font-size: 1rem;
    }

    .email-info-value {
        font-weight: 700;
        font-size: 18px;
    }

    .continue-application-button {
        border: solid #040b50;
        border-width: thin;
        border-radius: 5px;
        font-weight: 700;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
        text-align: left;
        flex: 1 1;
        cursor: pointer;
        padding: 0.5rem 0.25rem;

        background-color: #040b50;
        color: #ffffff;
        margin-top: 2.5rem;
    }

    @media(min-width: 800px) {
        .page-container {
            background-color: white;
        }

        .email-info-field {
            font-size: 1rem;
        }

        .email-info-value {
            font-size: 1.25rem;
        }

        .email-info-container {
            background-color: #f2f2f2;
            padding: 1.5rem 3rem 1.5rem 3rem;
        }

        .email-info-line {
            border: 0;
            padding: .75rem;
            flex-direction: row;
            align-items: center;
        }

        .email-info-content {
            display: grid;
            grid-template-columns: 12rem 1fr;
            align-items: center;
            row-gap: 1.25rem;
        }

        .continue-button-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        .continue-application-button {
            max-width: 16rem;
        }
    }
}