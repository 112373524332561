
#AppStatusContainer {

    @media (min-width: 801px) {  
        .app-status-container{ 
            width: 25rem;
        }
    }

    .component-width {
        width:100%;
    }
  
    .first-col-container-row1 {
        flex:1;
    }

    .first-col-container {
        display:flex;
        
    }
    .first-col-line-container {
        display:flex;
        align-items:center;
        flex:1;
    }
    .app-status-container{
        display: flex;
        padding-top:1rem;
        padding-bottom: 1rem;
        display:flex;
        flex-direction:column;
    }
    
    .app-status-container-big {
        width: 100%;
    }
    
    .app-status-tracker-container{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        text-align: center;
    }
    
    .current-status-text {
        font-size: 1.375em;
        font-weight: 700;
        width:100%;
        color: #040b50;
    }
    
    .app-status-text {
        font-weight: 500;
        width:100%;
        color: #040b50;
        font-size: 1.25em;
    }

    
    .big-status-text {
        font-size: unset;
    }

    .description-text {
        width: 100%;
        font-size: 1.125rem;
        font-weight: 500;
        margin-top: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    
    .tracker-item {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .tracker-items {
        display: unset;
        flex-direction: unset;
        align-items: unset;
    }
    
    .line {
        flex:1;
        height: 5px;
        border: 2px solid #d1cfcf;
        background-color: #d1cfcf;
        box-sizing: border-box;
    }
    
    .line-filled {
        flex:1;
        height: 5px;
        border: 2px solid #919ecb;
        background-color: #919ecb;
        box-sizing: border-box;
    }
    
    .app-status-row {
        display:flex;
        flex-direction:row;
        justify-content: space-between;
    }
    
    .app-status-column {
        display:flex;
        flex-direction:row;
        flex:1;
        justify-content: space-between;
    }

    .hide-status-column {
        display: none;
    }

    .center-text {
        text-align:center;
    }
    
    .app-status-div-dot {
        width:1rem;
        height: 1rem;
        text-align: center;
    }
    
    .app-status-div-dot-big {
        width: 1.25rem;
        height: 1.25rem;
        text-align: center;
    }
    
    
    .app-status-left-part {
        flex-grow:1;
        align-content: center;
    }
    
    .app-status-right-part {
        flex-grow:1;
        align-content: center;
    }
    
    .dot-image {
        width: 100%;
        height:100%;
    }

    .clickable {
        cursor: pointer;
    }

    .completed-item-container {
        display: flex;
        flex-direction: row;
        gap: .20vw;
        align-items: center;
        width: fit-content;
    }

    .completed-item{
        font-size: 1.125rem;
        font-weight: 800;
        color: #226212;
        font-family: "Barlow Medium", "Barlow", sans-serif;
    }

    .header-icon {
        background-color: transparent;
        padding-bottom: 15%;
        padding-right: 0.5rem
    }

    .navigation-header-item{
        font-size: 1.125rem;
        font-weight: 600;
        font-family: "Barlow Medium", "Barlow", sans-serif;
    }

    @media (min-width: 801px) {    

        .tracker-items {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .app-status-div-dot-big {
            width:2.25rem;
            height: 2.25rem;
            text-align: center;
        }

        .description-text {
            margin-left: 1rem;
        }

        .first-col-container {
            margin-left:1rem;
            width: 7rem;
        }

        .big-status-text {
            font-size: 1.75rem;
            font-weight: 700;
        }

        .app-status-first-column {
            margin-left: 3rem;
        }
        .hide-status-column {
            display: flex;
        }
    }
    
}
