#EmploymentHistory{
    background-color:  #f2f2f2;
    width: 100%;
    display:flex;
    flex-direction: column;
    flex-grow:1;
    overflow:auto;

    .employment-history-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding: 0 1rem 1rem 1rem;
        flex-grow: 1;
        overflow:auto;
        scrollbar-gutter: stable;
    }

    .employment-history-container {
        width: 100%;
    }

    .records-container {
        overflow: auto;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }

    .new-record-button{
        width: 11rem;
        height: 2.4rem;
        padding: 2px; 
        color: #040b50;
        border-radius: 5px;
        font-weight: 600;
        border-color: #040b50;
        border-style: solid;
        border-width: thin;
        cursor: pointer;
        background-color: white;
        min-width: fit-content;
    }
    .new-record-button:disabled{
        color: #cfcfcf;
        border-color: dimgray;
        cursor:default;
        border-width: 1px;
    }
    .new-record-button-content{
        display:flex;
        flex-direction: row;
        justify-content: space-evenly;
        font-size:20px;
    }

    .new-record-button-content img {
        width: 1rem;
        height: 1rem;
        margin-top: auto;
        margin-bottom: auto;
    }

    @media (min-width: 801px) {
        background-color:  white;

        .employment-history-wrapper {
            padding: 2.5rem 10.5rem;
        }
       
        .previousNextButtonHeader {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }        
    }
}