#employment-history-form {
    width: 100%;
    display:flex;
    flex-direction: column;
    flex-grow:1;
    background-color:  white;        
    
    @media(max-width: 800px) {
        background-color: unset;
    }

    //unsets absolute position on multiselect wrapper which caused styling issues
    .p-hidden-accessible {
        position: unset;
    }
    .p-multiselect {
        margin-top: unset;
    }

    .record-title{
        font-size: 1.3rem;
        font-weight: 600;
        padding-bottom: .8rem;
    }

    .record-container{        
        background-color: white;
        border-radius: 7px;
        border:1px solid #000b57;
        display: flex;
        flex-direction: column;
        justify-content:  center;
        padding: .75rem;
        margin:1px;  
        overflow: auto;
    }

    .select-box, input {
        height: 2rem;
        border-radius: 5px;
        border: 1px solid #000b57;
        font-weight: 450;
        color: #000b57;
        font-size: large;
        background-color: white;
    }
    .state-select-dropdown {
        height: 2rem;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #000b57;
        font-weight: 450;
        color: #000b57;
        font-size: large;
        background-color: white;
    }

    .application-checkbox {
        border-radius: initial;
        margin-bottom: 0;
        margin-left: 0;
        max-height: 1.1rem;
        min-width: 1.1rem;
    }

    .month-select {
        width:100%;
    }
    .year-select {
        width:100%;
    }
    .input-box{
        width:14rem;
    }

    .input-box-staffing{
        width:14rem;
        border: 1px solid #000b57;
        height: 2rem;
        font-weight: 450;
        font-size: large;
        color: #000b57;
    }

    .city-input-box{
        width:100%;
        border: 1px solid #000b57;
        height: 2rem;
        font-weight: 450;
        font-size: large;
        color: #000b57;
    }

    .form-element {
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        font-weight:500;
        font-size:18px;
    }

    .form-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 5%;
    }

    label, .form-label{
        display: block;
        padding-bottom:0.3rem;
        font-weight: 550;
    }

    .date-container {
        display: flex;
        flex-direction: column;
        width: 100%
    }

    .end-date-label-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
    }

    .charge-nurse-checkbox-container {
        display: flex;
        align-items: center;
        margin-top: 1.5rem;
        
        label{
            padding-left: .2rem;
        }
    }

    .still-work-here-checkbox-container {
        display: flex;
        align-items: center;
        
        label{
            padding-left: .2rem;
            padding-bottom: 0;
        }
    }

    .facility-location-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .facility-location-inputs {
        display: flex;
    }

    .state-dropdown {
        width: 40%;
        padding-left: .8rem;
    }

    .city-input {
        width: 60%;
    }

    .date-select-container {
        display: flex;
        width:100%
    }

    .month-select-container {
        width: 50%;
    }

    .year-select-container {
        padding-left: .8rem;
        width: 50%;
    }

    .profession-emr-row, .profession-container, .profession-select {
        width: 100%;
    }

    .city-input-wrapper {
        width: 100%;
        padding-right: .8rem;
    }

    .specialties {
        display: flex;
        flex-direction: column;
        background-color: grey;
    }

    .selected{
        background-color: #e9a91d;
    }

    .unselected{
        background-color: white;
    }

    .selections{
        flex: 1 0 30%;
        display: flex;
        justify-content: flex-end;
    }

    .yes-no-button-container{
        display: flex;
        flex-direction: row;
        height:100%;
        background-color: white;
        font-size: 20px;
        border-radius: 10px;
        border: 1px grey solid;
        max-width: 254px;
        width: 100%;
    }  

    .yes-border{
        border-right:1px grey solid;
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
    }

    .no-border {
        border-left:1px grey solid;
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
    }

    .selection-item{
        width: 50%;
        height:100%;
        padding: .5rem 1.7rem;
        text-align: center;
        cursor:pointer;
    }

    .specialties-wrapper {
        padding: 1rem;
    }

    .specialty-select {
        min-height: 6rem;
        max-height: -moz-fit-content;
        max-height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: .8rem;        
    }

    .select-checkbox { 
        display: flex;
        flex-direction: row;
    }

    .margin-bottom-2 {
        margin-bottom: 1rem;
    }

    .emr-label {
        margin-bottom: -1rem;
    }
    .specialties-input {
        width: 100%;
        height: 4rem;
        font-size: 1rem;
        font-family: "Barlow", sans-serif;
        color: #000b57;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding: 5px;
        border-color: #000b57;
        border-radius: 5px;
        font-weight: 600;
    }

    .float-label {
        padding-right: 2.75rem;
    }

    .specialty-label {
        padding-bottom: 0rem;
        font-size: 18px;
    }

    .label-border {
        border-bottom: 1px solid #040b50;
    }

    .emr-label {
        margin-bottom: -1rem;
    }

    .checkbox-label {
        padding-bottom: 0px;
        font-size: 18px;
    }

    .specialty-section {
        display: flex;
        flex-direction: column;
        background-color: white;
        height: 18rem;
        padding: 0
    }

    @media (min-width: 1250px) {
        background-color:  white;     
        
        .record-container {
            padding: 3rem;
            background-color: #f2f2f2;
        }

        .month-select {
            width:7rem;
        }
        .year-select {
            width:7rem;
        }

        .float-label {
            margin-top: unset;
        }

        .specialty-float-container {
            margin-top: unset;
        }

        .still-work-here-checkbox-container {
            padding-left: 3rem;
            margin-bottom: .3rem;
            label {
                padding-left: .2rem;
            }   
        }

        .end-date-label-container {
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-bottom: unset
        }      
        
        .specialty-select {
            width: unset;
        }

        .employment-type-container, .facility-location-container, .staffing-agency-container, .facility-name-container, .date-container, .profession-select, #emrIdMultiSelect, .shifts-per-month-container {
            width: 19rem;
        }        

        .employment-history-select {
            width: 100%;
        }

        .date-select-container {
            width:unset;
        }

        .profession-emr-row, .profession-container {
            width: unset;
        }

        .selections{
            justify-content: flex-start;
        }    
        
        .charge-nurse-checkbox-container {
            margin-top: 1rem;
        } 

        .charge-nurse-checkbox {            
            margin-left: unset;           
        }
        
        .specilty-charge-section {
            width: 45%;
            min-width: 16rem;
        }

        .label-border{
            border: unset;
        }     

        .specialty-section {
            display: flex;
            background-color: white;
            border: 1px solid black;
            border-radius: 10px;
            padding: 2rem 3.75rem 2rem 3.75rem;
            height: 18rem;
            flex-direction: row;
            justify-content: space-between;
            gap: 5rem;
            overflow: auto;
        }
    }
}