.db-item {
    margin-bottom: 2rem;
    padding:1rem;
    font-size: 1.25rem;
    color: #000b57;
    font-weight: 600;
    width: 60%;
}

.db-item-date-added {
    font-size:1rem;
}
.db-item-container {
    display: flex;
    border:1px solid black;
    border-radius:5px;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    padding: 1rem 1.25rem;
    align-items: center;
    background-color:  #f1fafc;

}
.db-item-details {
    display: flex;
    flex-direction: column;
    gap:.75rem;
}
.db-item-pdf-icon {
    img{
        width: 48px;
    }
}
.db-item-button-container{
    display: flex;
    padding-top: 1rem;
    flex-direction: row;
    justify-content: space-between;
    gap: .65rem;
}

.db-item-button-view{
    display: flex;
    justify-content: center;
    align-self: center;
    cursor: pointer;
    padding: 0.5rem .5rem;
    border: 1px solid  #040b50;
    border-radius: 5px;
    font-weight: 700;
    font-size: 1.25rem;
    max-width: 45%;
    min-width: 45%;
    text-align: center;
}

.db-item-button-delete{
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    padding: 0.5rem .5rem;
    border: 1px solid #fa0000;
    border-radius: 5px;
    color: #fa0000;
    font-size: 1.25rem;
    font-weight: 700;
    text-align: center;
    min-width: 45%;
    align-content: center;
    align-self: center;
        
}

.db-item-delete-button-content{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.trash-can-container{
    display: flex;
}

.trash-can {
    width: 1.5rem;
    padding-left: 0.5rem;
    align-self: center;
}

@media (max-width: 1250px) {
    .db-item-delete-button-content {
        display: flex;
        gap: .5rem;
        align-items: center;
    }
    .db-item-button-delete {
        font-size: 1rem;
        background-color: #fff;
    }
    .db-item-button-view {
        display: flex;
        font-size: 1rem;
        gap: .5rem;
        align-items: center;
        justify-content: center;
        background-color: #fff;
    }
    .trash-can {
        display: none;
    }

    .db-item {
        width: 100%;
        padding: unset;
    }
}

