#refer-a-friend{
    height: 100vh;
    .referral-container{
        display:flex;
        flex-direction: column;
        padding: 2rem 14rem;
        gap:1rem;
        height: 100vh;

        .referral-select{
            border-radius: 5px;
            border: 1px solid #000b57;
            font-size: 1.2rem;
            height: 2rem;
        } 
        
        .referral-button-container {
            align-self: flex-end;
        }
        
        .referral-header {
            font-size: 1.25rem;
            font-weight: 600;
            color: #040b50;
            width: 100%;
        }

        .referral-form {
            background-color:#f2f2f2;
            border: 1px solid #293069;
            display:flex;
            flex-direction: row;
            justify-content:center;
            flex-wrap: wrap;
            border-radius: 10px;
            
            padding: 3rem 1rem;
            gap: 4rem;
            
            min-width: auto;

            .referral-row1,.referral-row2 {
                display: flex;
                width:100%;
                flex-wrap: wrap;
                justify-content: space-between;

                    .referral-input-container{
                        display: flex;
                        flex-direction: column;
                        width: 30%;
                        align-items: center;

                        input {
                            font-size: 1.2rem;
                            border: 1px solid #000b57;
                            height: 2rem;
                            max-width: 18.6rem;
                            width: 100%;
                        }
                        label {
                            font-weight: 550;
                            font-size: large;
                            width: 100%;
                            max-width: 18.6rem;
                        }
                        select {
                            font-size: large;
                            width: 100%;
                            max-width: 18.6rem;
                        }
                    }
            }

            .referral-row2{
                justify-content: flex-start;
                gap: 5%;
                label {
                    text-wrap: nowrap;
                }
            }

        }
        #referral-submit-button{
            align-self: flex-end;
            cursor: pointer;
            display: flex;
            background-color: #040b50;
            color: white;
            border-radius: 5px;
            padding: .75rem 3rem;
            gap: 0.5rem;
            align-items: center;
            font-weight: 600;
            justify-content: center;
            width: fit-content;
            font-size: 1rem;
            border: 1px solid white;            
        }
        #referral-submit-button:disabled{
            cursor: default;
            background-color: #9c9eb2;
            color: #cfcfcf;
        }
        .referral-rows{
            display:flex;
            flex-direction: column;
            gap: 3rem;
            align-self: center;
            width: 90%;
            flex-wrap: wrap;
            align-content: center;
        }
    }

    .referral-green-check-mark{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:3rem;
        height: 100vh;
        background-color: white;
        text-align: center;

        img{
            width: 16rem;
            padding-left: 1rem;
        }

        .referral-success-text {
            color: #226212;
            font-weight: 550;
            font-size:3rem;
            padding:2rem;
        }
    }

    

    @media (max-width: 1249px){
        overflow-y: scroll;
        .referral-green-check-mark {
            background-color: #f2f2f2;
        }
        
        .referral-container{
            width: 100%;
            padding: 1rem;
            background-color: #f2f2f2;
            display: flex;
            flex-direction: column;
            overflow:auto;

        
            .referral-header {
                display: flex;
                text-align: center;
                font-style: italic;
                min-width: 100%;
            }
            
            .referral-form {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                padding: 3rem;
                background-color: white;
                .referral-row1,.referral-row2{
                    flex-wrap: wrap; 
                    flex-direction: column;
                    gap:3rem;
                    .referral-input-container{
                        width: 100%;
                        label, select, input{
                            font-size: 1.2rem;
                            height: 2rem;
                            min-width: 100%;
                            text-wrap: nowrap;
                        }
                    }      
                }
            }
            .referral-button-container {
                width: 100%;
                button {
                    width:100%!important;
                }
            }
        }
    }

    @media(max-width:940px){
        overflow-y:scroll;

        .referral-green-check-mark {
            background-color: #f2f2f2;
        }
        .referral-container {
            flex-grow:1;
            display: flex;
            overflow: auto;

            .referral-form{
                padding: 2rem;
                .referral-row1,.referral-row2{
                    flex-direction: column;
                    gap:3rem;
                    .referral-input-container{
                        width: 100%;
                    }
                }
            }

            .referral-button-container {
                width: 100%;
                button {
                    width:100%!important;
                }
            }
        }
    }
}