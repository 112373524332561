.requirementListItem {
    margin-bottom: 0.5rem;
}
.requirementsList {
    margin-right: 1rem;
    margin-left: 1rem;
}

@media (max-width: 800px) {
    .requirementsList {
        margin-left: 0;
    }
}