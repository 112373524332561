.drag-drop {
    background: #fff;
    border: 1px solid var(--border-color);
    border-radius: 8px;
  }
  .document-uploader {
    padding-left: 1rem;
    border: 1px solid rgba(4, 11, 80, 0.99);
    padding-bottom: 5rem;
    border-radius: 5px;
    box-shadow: inset 1px 1px 6px 1px gray;
    font-weight: 600;
    font-style:italic;

  
    &.active {
      border-color: #6dc24b;
    }
  
    .upload-info {
      display: flex;
      margin-bottom: 1rem;
    }
  
    .success-file {
      display: flex;
      align-items: center;
      color: #6dc24b;
     
    }
  
    input[type="file"] {
      display: none;
    }
  }