#SkillsChecklist {
    font-family: "Barlow Medium", "Barlow", sans-serif;
    font-weight: 500;
    color: #040b50;
    display: flex;
    flex-grow: 1;
    overflow: auto;
    flex-direction: column;

    .application-page-container {
        background-color: #f2f2f2;
        display: flex;
        flex-direction: column;
    }
    
    .checklist-container {
        padding: 2rem 2rem 4rem 2rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 1px solid #293069;
        background-color: white;
        border-radius: 10px;
        gap: 1.5rem;
    }

    .container-description{
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size:20px;
        color:#000b57;
        font-weight:600;
    }

    .checklist-item-name {
        font-size:1.25rem;
        font-weight:600;
        padding-left: .5rem;
    }

    .checklist-item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        padding: 0.75rem .5rem;
        height: -moz-fit-content;
        height: fit-content;
        background-color: #f2f2f2;
        border: 1px solid black;
        border-radius: 10px;
        margin-bottom: 1.5rem;
    }

    .take-all-button {
        padding-top: 1.5rem;
        display: flex;
        justify-content: right;
    }

    .action-button {
        border: solid #040b50;
        border-width: thin;
        border-radius: 5px;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
        text-align: left;
        cursor: pointer;
        padding: 1.5rem;
        width: fit-content;
        height:2rem;
        color: white;
        background-color: #000b57;
        font-weight:600;
        align-self: flex-end;
        white-space: nowrap;
    }

    .action-button:disabled{
        background-color: #9c9eb2;
        color: #cfcfcf;
        border-width: 1px;
        cursor:default;
    }

    @media(min-width: 800px) {
        .application-page-container {
            background-color: white;
        }

        .checklist-item-container {
            border-radius: 10px;
            border: 1px solid black;
        }

        .checklist-item {
            background-color: white;
            border-radius: unset;
            border: 0px;
            border-bottom: 1px solid black;
            padding: 2rem 1rem 2rem 1rem;
        }

        .checklist-item:first-child {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
        }

        .checklist-item:last-child {
            border-bottom-left-radius: inherit;
            border-bottom-right-radius: inherit;
            border-bottom: unset;

        }

        .checklist-item {
            margin-bottom: unset;
        }

        .checklist-container {
            background-color: #f2f2f2;
            padding: 4rem 2rem;
        }

        .action-button {            
            min-width: 21.5rem;
        }

        .take-checklist-button {
            min-width: 21.5rem;
        }
    }
}