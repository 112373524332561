.EmploymentHistoryRecord{
    margin-bottom: 1rem;

    .edit-record-button{
        width: 11rem;
        height: 2.5rem;
        padding-top: 2px; 
        border-radius: 5px;
        font-size: 1.1rem;
        font-weight: 600;
        border-color: #040b50;
        color:#040b50;
        background-color: white;
        border-style: solid;
        cursor: pointer;    
    }

    .edit-record-button-disabled {
        background-color: #ffffff;
        color: #cfcfcf;
        border-color: rgb(102, 100, 100);
        border-width: 1px;
        cursor:default;
    }

    .save-record-button{
        width: 11rem;
        height: 2.5rem;
        padding-top: 2px; 
        border-radius: 5px;
        font-size: 1.1rem;
        font-weight: 600;
        border-color: #040b50;
        color:white;
        background-color: #040b50 ;
        border-style: solid;
        cursor: pointer;    
    }

    .verified-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        color:#4caf50;
        font-weight: 600;
        padding-top: 1.5rem;
        font-size: unset;
    }

    .verified-text-checkmark {
        height: 2rem;
        width: 2rem;
        margin-right: 0.4rem;
    }
    
    .save-record-button:disabled{
        background-color: #9c9eb2;
        color: #cfcfcf;
        border-width: 1px;
        cursor:default;
    }
    .save-record-button-container{
        display: flex;
        justify-content: right;
        width:49%;
        padding-top: 1rem; 
    }

    .save-delete-button-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 1.5rem;
    }

    .delete-record-button-container {
        display: flex;
        justify-content: left;
        width:49%;
        padding-top: 1rem; 
    }

    .delete-record-button{
        display: flex;
        justify-content: space-around;
        width: 11rem;
        height: 2.5rem;
        padding-top: 2px; 
        border-radius: 5px;
        font-size: 1.1rem;
        font-weight: 600;
        border-style: solid;
        cursor: pointer;
        border-color: #fa0000;
        color: #fa0000;
        background-color: #ffffff;
        align-items: center;
        min-width: auto;
    }

    .delete-record-button:disabled{
        background-color: #ffffff;
        color: #cfcfcf;
        border-color: rgb(102, 100, 100);
        border-width: 1px;
        cursor:default;
    }

    .delete-record-text {
        min-width: fit-content;
    }

    .record-summary {
        display: flex;
        flex-direction: column;
        border: 1px solid #040b50;
        border-radius: 5px;
        background-color: #f5fcfd;
        padding: 1rem;
        gap: .5rem;
    }

    .record-summary-facility {
        font-weight: bold;
        font-size: 1.5rem;
    }

    .record-summary-duration {
        font-weight: 600;
        font-size: 1rem;
    }   

    @media (min-width: 801px) {
        
        .verified-text {
            font-size: 1.25em;
        }
    }
}