@font-face{
  font-family: "Barlow";
  src: url(./fonts/Barlow-Regular.ttf) format("truetype"), local("Barlow")
}

@font-face{
  font-family: "Barlow";
  font-weight: 700;
  src: url(./fonts/Barlow-Bold.ttf) format("truetype"), local("Barlow")
}

body {
  margin: 0;
  font-family: "Barlow", sans-serif;
  color: #000b57;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  display:flex;
  flex-direction: column;
}