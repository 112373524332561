#ProfilePage {
    width: 100%;
    display:flex;
    flex-direction: column;
    height:100%;
    overflow:auto;
    word-break: break-word;
    
    h2 {
        font-family: "Barlow-Bold", "Barlow", sans-serif;
        font-size: 1.3rem;
        margin-bottom: 0;
        margin-top: 0;
        font-weight:500;
    }
    
    .title {
        font-weight: bold;
        width: 100%;
    }

    .page-container {
        display: flex;
        flex-direction: column;
        padding: 1rem 1rem;
        overflow: auto;
        flex-grow: 1;
        background-color: #f2f2f2;
        gap:2rem;

    }

    .container-header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        background-color: #135786;
        color: #fff;
        font-weight: bold;
        border-radius: 0;
        text-overflow: ellipsis;
        text-align: center;
        font-size: 18px;
        border-radius: 10px 10px 0 0;
        border: 1px solid #797979;
    }

    .profile-container-info {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
        background-color: white;
        border-radius: 0 0 10px 10px;
        border-left: 1px solid #797979;
        border-bottom: 1px solid #797979;
        border-right: 1px solid #797979;
    }

    .licensedata-info {
        display: flex;
        flex-direction: column;
        padding: 1rem 2rem 1rem 2rem;
        gap: 1rem;
        background-color: white;
        border-radius: 0 0 10px 10px;
        border-left: 1px solid #797979;
        border-bottom: 1px solid #797979;
        border-right: 1px solid #797979;
    }

    .biodata-info-title {
        font-size: 18px;
        padding-bottom: .25rem;
        font-weight: 600;
    }
    
    .info-value {
        font-size:15px;
        word-break: break-word;
    }

    .item-container-header{
        display: flex;
        background-color: #D2DCF3;
        justify-content: center;
        padding:1rem;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color:#000b57;
        font-weight: 600;
        font-size: 18px;
        flex-direction: column;
        align-items: center;
    }

    .item-container{
        display:flex;
        flex-direction: column;
        border: 1px solid black;
        border-radius: 10px;
    }

    .item-container-info{
        display:flex;
        flex-direction: column;
        padding:1rem;
        gap:1rem;
    }

    
    @media(min-width: 1251px) {
        .page-container{
            background-color: white;
            padding: 1rem 5.5rem 4rem 5.5rem;
        }
        .section-title {
            align-items: center;
            justify-content: left;
            background-color: #fff;
            color: #000b57;
            font-size: 20px;
            font-weight: bold;
        }

        .application-text {
            color: #000b57;
            font-size: large;
            font-weight: 550;
            align-content: center;
            display:unset;
        }

        .biodata-container {
            border: unset;
        }

        .profile-container {
            background-color: unset;
            border:unset;
        }

        .biodata-info-container {
            
            padding: 0rem 2rem 0rem 2rem;
        }

        .container-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: .5rem 4rem .5rem 1rem;
            font-size: 20px;
            color:#000b57;
            background-color: transparent;
            border-radius: unset;
            border: unset;
            padding-bottom: .8rem;
        }

        .profile-container-info {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            background-color: #f2f2f2;
            border-radius: 10px;
            padding: 1rem;
            gap: 1.5rem;
            margin-left: 4rem;
            margin-right: 4rem;
            border: 1px solid #797979;
        }

        .profile-table-header {
            display:flex;
            font-size: 19px;
            font-weight:bold;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #f2f2f2;
            padding:1vw;
            border-top: 1px solid #797979;
            border-left: 1px solid #797979;
        }

        .fill-color {
            display: block;
            height: 40px;
            width: 40px;
            color: var(--color);
          }

        .profile-grid {
            display: grid;
            grid-area: header;
            grid-template-columns: repeat(var(--numberOfColumns), 1fr);
            margin-left: 4rem;
            margin-right: 4rem;
            min-width:fit-content;
            border-right: 1px solid #797979;
            border-bottom: 1px solid #797979;
        }

        .profile-table-column{
            display:flex;
            padding:1vw;
            font-size: 19px;
            font-weight:500;
            justify-content: center;
            align-items: center;
            border-top: 1px solid #797979;
            border-left: 1px solid #797979;
        }

        .info-value {
            font-size: 1.25rem;
            font-weight: 600;
        }
    }
}