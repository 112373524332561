#ResetPasswordPage{
    display: flex;
    flex-direction: column;
    font-family: "Barlow Medium", "Barlow", sans-serif;
    .reset-button-container{
        width: 22rem;
    }
    .centered-text{
        text-align: center;
    }
    .login-button{
        background-color: white;
        color: #000b57;
        border: 1px solid #000b57 !important;
    }
    .login-button-container{
        margin: 0 auto;
        width: 50%;
    }
    button{
        height: 40px;
        justify-content: center;
        padding: 0.5rem;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 18px;
        font-weight: 600;
        border-radius: 5px;
        color: white;
        background-color: #000b57;
        width: 100%;
        margin-top: 1rem;
    }
    .forgot-form {
        font-weight: 600;
        font-size: 1.2rem;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
    }
    .forgot-input {
        border-radius: 10px;
        border: 1px solid #000b57;
        background-color: rgba(242, 242, 242, 0.99);
        padding: 1em;
        width: 40rem;
    }
    .password-complete {
        display: flex;
        flex-direction: column;    
        align-items: center;
        .password-complete-button {
            width: 100%;
            padding: 2rem 1.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .password-complete-message {
            padding:2rem 2rem 0rem 2rem;
            font-weight: 500;
            font-size: 18px;
        }
    }
    .body-space{
        padding:2rem;
    }
    .reset-password-header {
        font-size: 1.5rem;
        color: #000b57;
        border-bottom: 1px solid #000b57;
        font-weight: 700;
        height: 3.5rem;
        background: #d2dcf3;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .reset-password-body {
        display:flex;
        flex-direction: column;
        padding:20px;
        width: 70%;
        border: 1px solid #000b57;
        border-radius: 7px;
        gap:10px;
        font-size: 18px;

        .body-header{
            font-weight: 700;
        }
        .body-body {
            padding:15px 0px;
            gap:10px;
            display:flex;
            flex-direction: column;
            
        }
        .inputContainer{
            display: flex;
            flex-direction: column;
            label {
                font-weight: 600;
                padding:8px 0px;
            }
            input {
                width: 22rem;
                height: 2rem;
            }
        }
    }
    .alert {
        height: 1rem;
        width: 1rem;
        vertical-align: middle;
        margin-right: 0.5rem;
    }
    .invalid-field-alert-text {
        color: #E81111;
        font-style: italic;
        font-weight: 500;
    }
    input {
        width: 100%;
        height: 2rem;
        border: 1px solid black;
    }
    .submission-received-helper-text{
        margin: 0;
        font-family: "Barlow", sans-serif;
        color: #000b57;
        font-weight: 600;
        
    }

    .submission-received-container{
        padding: 2rem;
    }
    
    @media(max-width: 800px){
        .forgot-input{
            width: auto;
        }
        .body-space{
            padding-right:2rem;
        }
        button {
            width: 98%;
        }
        .reset-password-body {
            width: 100%;
            .body-body {
                button {
                    width: 16rem;
                }
                .inputContainer{
                    input {
                        width: 16rem;
                        height: 2rem;
                    }
                }
            }
        }
        .reset-password-header {
            width: 100%;
        }
    
    }
}