#update-background-questions-submit-modal{
    position: fixed;
    display:flex;       
    overflow: auto;
    justify-content: center;

    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    border: none;
    z-index: 99;
    padding: 4.7rem 1rem;


    .confirmation-modal {
        display: flex;
        flex-direction: column;
        width:100%;
        height:60%;
        border: 1px solid #040b50;
        border-radius: 10px;
        align-items: center;
        flex-wrap: wrap;
        background-color: white;
        padding: 5rem 1rem 3rem 1rem;
        
    }

    .confirmation-modal img{
        width: 16rem;
    }

    .confirmation-modal span {
        color: #226212;
        font-weight: 550;
        font-size:2.75rem;
        padding:2rem;
        text-align: center;
        
    }

    @media (max-width: 1249px) {
        align-items: stretch;
        justify-content: stretch;
        padding: 0;

        .confirmation-modal{
            height: 100vh;
            padding: 0rem;
            border-radius: unset;
        }

        .success-banner {
            background-color: #226212;
            width: 100%;
            height: 4rem;
            color: white;
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-weight: 550;
            font-size: 1.75rem;
        }
        .confirmation-modal img{
            width: 12rem;
            padding-top: 4rem;
        }

        .confirmation-modal span {
            font-weight: 550;
            font-size:2rem;
            padding:2rem;
            text-align: center;
            
        }

    }

    
}