#JobOffersCard {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    .top-data{
        display: flex;
        flex-direction: column;
        padding:.75rem;
        gap:.25rem;

        div{
            padding:.25rem;
        }
    }

    .mobile-offer-title {
        background-color: #43a047;
        width:fit-content;
        border-radius: 4px;
        color:#fff;
        font-weight: 500;
        font-size: 1rem;
        display: flex;
        gap: .5rem;
        padding: .25rem .5rem!important;
        margin-bottom: .75rem;
    }
    a{
        color:#000B57;
        text-decoration: none;
    }

    .archived{
        display: flex;
        flex-direction: column;
        border-radius: inherit;
        color:grey;
    }

    .offer-title {
        width: 100%;
        height: 2rem;
        background-color:  #225f13;
        color: #ffffff;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        text-align: center;
        align-content: center;
        font-weight: 700;
        font-size: 1.25rem
    }

    .selected{
        display: flex;
        flex-direction: column;
        border: 3px solid  #225f13;
        border-radius: inherit;
        background-color: #f2f2f2;
    }

    .unselected{
        display: flex;
        flex-direction: column;
        border: 1px solid #040b50;
        border-radius: inherit;
        background-color: white;
        margin-right:1.5rem;
    }

    .closeMatch{
        display: flex;
        justify-content: center;
        align-items: center;
        padding:.25rem;
        background-color: #fff495;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom:1px solid #040b50;
        font-weight:bold;
        color:black;
    }

    .exactMatch{
        display: flex;
        justify-content: center;
        align-items: center;
        padding:.25rem;
        background-color: #ccffcc;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom:1px solid #040b50;
        font-weight:bold;
        color:black;
    }

    
    .clock-icon {
        height: 1.25rem;
    }
    .shift-container{
        display: flex;
        padding:.25rem;
        gap:.25rem;
        align-items: center;
    }
    .shift-icon{
        height:1.25rem;
    }

    .bottom-data{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:.75rem;
        border-top:1px solid #040b50;
        font-size: 18px;
    }

    .location-data-container{
        display: flex;
        flex-direction: column;
        padding:.25rem;
    }

    .miles-from-location{
        font-size: .833rem;
        font-style: italic;
    }

    .match-highlight{
        background-color:#fff495;
        border-radius: 5px;
        width:fit-content;
    }

    .bold{
        font-weight: bold;
    }
    
    @media (max-width: 1250px) {
        #SignContractButton {
            
        }
        .sign-contract-button-container {
            display: flex;
            justify-content: center;
            padding-top: 2rem;
        }
        
        .sign-contract-button {
            width: max-content;
            height: min-content;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            color: rgba(255, 255, 255, 0.99);
            box-sizing: border-box;
            font-family: "Barlow SemiBold", "Barlow", sans-serif;
            font-size: 1.2rem;
            font-weight: 600;
            background-color: rgba(4, 11, 80, 0.99);
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #040b50;
            cursor: pointer;
            align-self: center;

        }
        .selected{
            background-color: white;
        }

        .unselected{
            background-color: white;
            margin:unset;
        }
    }
}

#SignContractButton {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    
    //Moble
    @media (max-width: 1250px) {    
        
        padding-bottom: 2rem;
   
        .sign-contract-button-container {
            display: flex;
            justify-content: center;
            padding-top: 2rem;
        }
        
        .sign-contract-button {
            width: max-content;
            height: min-content;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            color: rgba(255, 255, 255, 0.99);
            box-sizing: border-box;
            font-family: "Barlow SemiBold", "Barlow", sans-serif;
            font-size: 1.2rem;
            font-weight: 600;
            background-color: rgba(4, 11, 80, 0.99);
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #040b50;
            cursor: pointer;
            align-self: center;

        }
        .selected{
            background-color: white;
        }

        .unselected{
            background-color: white;
            margin:unset;
        }
    }
}