#hcp-certifications{
    display: flex;
    flex-grow: 1;
    overflow:auto;
    flex-direction: column;
    background-color:  #f2f2f2;

    .empty-dropdown-item {
        display: none;
    }
    
    h2 {
        font-weight: 600;
        font-size: 1.3rem;
        margin: 0;

        @media (min-width: 801px) {
            font-size: 2rem;
        }
    }

    .certification-components{
        display: flex;
        flex-direction: row;
        padding-top: 1rem;
        padding-bottom:3rem;
        flex-wrap: wrap;
        row-gap: 1rem;
        column-gap: 10%;
    }

    .certification-component {
        width: 100%;
        flex-basis: 100%;
        @media (min-width: 801px) {
            flex-basis: 45%;
        }
    }

    .certification-item{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex-basis: 100%;
        height: 100%;
    }

    .certification-item-form{
        background-color: white;        
        border-radius: 5px;
        border-color: #040b50;
        border-style: solid;
        border-width: 1px;
        display: flex;
        flex-direction: column;
        font-size: 1.125rem;
        padding: 1rem;
        gap: 1rem;
        height: 100%;
        min-height: 14rem;

        @media (min-width: 801px) {
            background-color:  #f2f2f2;
            padding: 2rem 2rem 1rem 2rem;
        }

        label{
            font-weight: 550;
        }
    }

    

    .certification-dropdown {
        border-radius: 5px;
        height: 2rem;
        border: 1px solid #000b57;
        background-color: white;
        color: #000b57;
        font-weight: 450;
        width: 100%;
        font-size: 1.125rem;
    }

    .saved {
        display: flex;
        flex-direction: column;
        border: 1px solid #040b50;
        border-radius: 10px;
        background-color: #f5fcfd;
        padding: 1rem;
        gap: 0.5rem;
        min-height: 5.75rem;    
    }

    .certification-item h2 {
        font-size: 1.3rem;
    }
    .certification-item-saved-title {
        font-weight: bold;
        font-size: 1.3rem;
    }

    .certification-item-saved-expiration {
        font-weight: 500;
        font-size: 1.4rem;
    }

    .file-picker{
        border: unset;
    }

    .new-record-button{
        width: 11rem;
        height: 2.4rem;
        padding: 2px; 
        color: #040b50;
        border-radius: 5px;
        font-weight: 600;
        border-color: #040b50;
        border-style: solid;
        cursor: pointer;
        border-width: thin;
        background-color: white;
        min-width: fit-content;
    }

    .new-record-button:disabled{
        color: #cfcfcf;
        border-color: dimgray;
        cursor:default;
        border-width: 1px;
    }
    .new-record-button-content{
        display:flex;
        flex-direction: row;
        justify-content: space-evenly;
        font-size:20px;
    }
    
    .new-record-button-content img {
        width: 1rem;
        height: 1rem;
        margin-top: auto;
        margin-bottom: auto;
    }

    @media (min-width: 801px) {
        background-color: white;
        .certification-dropdown {
            width: min-content;
        }

        .title {
            border-bottom: .25rem solid #040b50;
            font-size: 2rem;
        }


        .previousNextButtonHeader {
            padding-top:1.5rem;
            padding-bottom:1.5rem;
        } 
    }
}