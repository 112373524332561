#FavoriteJobResults{
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-grow: 1;

    .in-active {
        color: #7d7d7d;
    }

    .archived-job-header{
        display: flex;
        border-radius: 10px 10px 0px 0px;
        border-bottom: 1px solid #797979;
        background-color: #FCD1CE;
        color: #000B57;
        align-items: center;
        padding: .25rem;
    }

    .left-pane {
        display: flex;
        flex-direction: column;
        overflow: auto;
        height:100vh;
        width:30rem;
        background-color: white;
        border: 1px #797979 solid;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .job-list{
        display: flex;
        flex-direction: column;
        overflow: auto;
        gap: 1rem;
        padding:1rem;
    }

    .favorites-page-container {
        display: flex;
        flex-direction: row;
        overflow-y: hidden;
        overflow-x:auto;
        gap:2rem;
        padding:1rem;
    }

    .page-header {
        background-color: #f5fcfd;
        padding: 1rem;
        border-bottom: 1px solid #797979;
        height: 4rem;
    }

    .page-title {
        margin-left: 0.5rem;
        padding-top: 2px;
        font-weight: bold;
        font-size: 24px;
    }

    .button-container{
        display: flex;
        flex-grow:1;
    }

    .button-container button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow:1;
        border-radius: 10px 10px 0px 0px;
        height: 3.1rem;
        border: 1px solid #797979;
        border-bottom: none;
        background-color: #ffffff;
        color: #000B57;
        font-weight: bold;
        font-size: large;
    }

    .button-container .selected {
        background-color: #135786;
        color: #ffffff;
    }

    .button-container .unselected {
        background-color: #ffffff;
        color: #000B57;
    }

    .button-and-jobs-container{
        display: flex;
        flex-direction: column;
    }


    @media (max-width: 1250px) {
        flex-grow: 1;
        overflow: auto;

        .favorites-page-container{
            padding:unset;
            flex-grow:1;
            overflow: auto;
        }
        .button-container{
            display: flex;
            flex-grow:1;
        }

        .button-container button{
            display: flex;
            flex-grow:1;
            align-items: center;
            justify-content: center; 
        }
        .button-page-container{
            display: flex;
            flex-grow:1;
            overflow: auto;
        }
        .button-and-jobs-container{
            display: flex;
            overflow:auto;
            flex-grow:1;            
        }
        .left-pane{
            display: flex;
            overflow:auto;
            flex-grow:1; 
            width:unset;
            border-bottom-left-radius: unset;
            border-bottom-right-radius: unset;
        }

        .container-style {
            padding: 1rem 0rem 0rem 0rem; 
        }

        .page-header{
            border-top: 1px solid #797979;
        }

        .page-title {
            display: flex;;
            font-size: 7vw;
            height: 100%;
            width: 100%;
            align-items: center;
        }
    }
}