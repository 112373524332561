#PreviousNextButtonHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top:1.5rem;
    justify-content: space-between;
    padding-bottom:1rem;

    .buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 3rem;
    }

    .previous-button-spacer {
        flex: 1;
        padding: 0 4px;
    }

    .previous-button, .save-and-proceed-button{
        border: solid #040b50;
        border-width: thin;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        text-align: left;
        flex: 1;
        cursor: pointer;
        padding: 0.5rem 0.25rem;
        width: 16rem;
    }

    .previous-button {
        background-color: #ffffff;
        color: #040b50;
        padding-left: .25rem;
        gap: .25rem;
    }

    .next-button-chevron {
        padding-right: 0.1rem;
    }

    .save-and-proceed-button{
        background-color: #040b50;
        color: #ffffff;
        gap: 0.5rem;
    }

    .save-and-proceed-button:disabled{
        cursor: default;
    }

    .save-and-proceed-button:disabled{
        background-color: #9c9eb2;
        color: #cfcfcf;
    }


    img.left-chevron{
        transform: rotate(180deg);
    }

    .mobile-text-container {
        display: flex;
        flex-direction: column;
    }

    .bolded {
        font-weight: bold;
    }

    @media(min-width: 801px){
        .previous-button, .save-and-proceed-button{
            flex-basis: 14rem;
            flex: unset;
        }

        .previous-button{
            font-weight: 500;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            padding-left: .75rem;
            padding-top: 1rem;
            padding: 0.75rem;
        }

        .save-and-proceed-button{
            font-weight: 500;
            font-size: 20px;
            padding: 0.75rem

        }

        .save-and-proceed-button span{
            width: unset;
        }
        
    }

}