
#SubmittedJobResults{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  height: 100%;

  .page-title-desktop {
    display: none;
  }

  .submission-list-title {
      display:none
  }

  .left-pane-container {
    display: flex;
    flex-direction: column;
    flex-grow:1;
    overflow: auto;
    border: 1px solid #797979;
    border-top: unset;
    min-width: 30.5rem;
    max-width:30.5rem;
    border-radius: 10px; 
    overflow-x: hidden;
  }

  .left-panel-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow:auto;
    background-color: #fff;
    padding:1rem;
    gap:1rem;
  }

  .offer-border {
    border: 5px green;
  }

  .submissions-jobs-container {
      display: flex;
      flex-direction: column;
      flex-grow:1;
      overflow: auto;
      background-color: #fff;

  }

  .offers-job-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    background-color: #fff;
    padding: 1rem;
    gap: 1rem;
  }

  .none-to-show {
    justify-content: center;
    margin-top: 2.5rem;
    font-weight: 700;
    text-align: center;
    font-size: 1.25rem;
  }

  .container-style {
    display: flex;
    flex-direction: row;
    overflow: auto;
    gap:2rem;
    background-color: #e1e1e1;
    flex-grow: 1;
  }

  .left-pane-header {
    display: flex;
    flex-direction: row;
    height: 3rem;
    background-color: #e1e1e1;
  }

  .left-tab-btn {
    height: 100%;
    border: 1px solid #797979;
    cursor: pointer;
    width: 50%;
    text-align: center;
    align-content: center;
    font-weight: 700;
    background-color: white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .btn-selected {
    background-color:  #135786;
    color:  #ffffff;
    min-height: 3rem;
  }
  
  
  
  @media (min-width: 1250px) {
    .page-title-mobile{
      display:none;
    }
  
    .page-title-desktop{
      display: unset;
    }

    .submission-list-title {
      display: unset;
      height: 3em;
      margin: 0;
      padding: 0.75em;
      background: #135786;
      color: #fff;
      font-weight: bold;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      text-align: center;
    }

    .submissions-jobs-container {
      min-width: 30.5rem;
      max-width: 30.5rem;
    }

    .container-style {
      padding: 2vh 1vw;
    }

    .submission-left-pane {
      display: flex;
      flex-grow:1;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      min-height:70vh;
      padding: 1rem;
      border: 1px solid #797979;
      gap: 1rem;
      flex-direction: column;
      overflow: auto;
    }
  }
  
  @media (max-width:1249px){
    .left-pane-container{
      min-width: 30%;
      max-width: 90%;
      border: none;
    }
    
    .left-panel-content {
      background-color: #e1e1e1;
      border: 1px solid #797979;
    }

    .submissions-jobs-container {
      background-color: #e1e1e1;
    }

    .btn-selected {
      background-color: #fff;
      color: #000b57;
      border-bottom:5px solid #000b57 !important;
    }

    .left-tab-btn {
      border: unset;
      font-size: 1.25rem;
    }

    .left-pane-header {
      background-color: #fff;
    }

    .container-style {
      background-color: #fff;
      justify-content: center;
      padding-bottom: 1rem;

    }


  }

}
