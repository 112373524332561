#NavigationContainer{   

    .resume-later-button {
        height: 2.5rem;
        justify-content: center;
        font-weight: 550;
        color: #040b50;
        border-radius: 5px;
        border: 1px solid #040b50;
        background-color: rgba(245, 178, 31, 0.3);
        font-size: 20px;
        cursor: pointer;

    }

    .navigation-application-status-background{
        background-color: #f5fcfd;
        border-top: 1px solid #797979;
        border-bottom: 1px solid grey;
    }

    .application-text {
        display:none;
    }

    .header-navigation-container {
        display:none;
    }

    .resume-later-button-container {
        align-content: center;
        padding-right: 1rem;
        padding-top: 1rem;
        position: absolute;
        right: 4rem;
        top: .4rem;
    }
    
    @media (min-width: 801px) {        
        display: flex;
        flex-direction: column;

        .resume-later-button-container {
            padding-right: unset;
            padding-top: unset;
            position: unset;
            right: unset;
            top: unset;
        }

        .header-navigation-container{
            display: flex;
            outline-color: green;
            width: 100%;
            height:4rem;
            justify-content: space-evenly;
            align-items: center;   
            background-color: #F2F2F2;     
            padding: 1rem;
            border-bottom: 1px solid grey;
        }

        .navigation-application-status-background{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            width:100%;
            background-color: #f5fcfd;
            border-top: 1px solid #797979;
            flex-flow: unset;
            overflow: auto;
        }
        
        .navigation-application-status-container{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width:100%;
            max-width: 100rem;
            padding: 0 1rem;
            align-self: center;
            background-color: #f5fcfd;
        }

        .application-text {
            color: #000b57;
            font-size:1.75em;
            font-weight: 700;
            align-content: center;
            display:unset;
        }

        .navigation-header-item{
            font-size: 1rem;
            font-weight: 600;
            font-family: "Barlow Medium", "Barlow", sans-serif;
        }

        .completed-item{
            font-size: 1rem;
            font-weight: 800;
            color: #226212;
            font-family: "Barlow Medium", "Barlow", sans-serif;
        }

        .selected-item{
            font-size: 1rem;
            font-weight: 600;
            border-bottom: 2px solid #000b57;
            font-family: "Barlow Medium", "Barlow", sans-serif;
        }
        
        .navigation-buttons-container{
            display: flex;
            width:100%;
            height:15%;
            justify-content: space-between;
            align-items: center;
            padding-left: 7rem;
            padding-right: 7rem;
        }

        .navigation-button-previous{
            width: 10rem;
            height:40%;
            border-radius: 5px;
            color:#000b57;
            background-color: white;
            border: 1px solid #000b57;
            font-weight:600;
            font-size: 16px;
            font-family: "Barlow Medium", "Barlow", sans-serif;
        }

        .navigation-button-save-and-proceed{
            display:flex;
            width: 10rem;
            height:40%;
            border-radius: 5px;
            color:white;
            background-color: #000b57;
            border: none;
            font-weight:600;
            align-items: center;
            font-size: 16px;
            font-family: "Barlow Medium", "Barlow", sans-serif;
        }
        
        .navigation-header-item-completed {
            display: flex;
            align-items: center;
            font-size: 1rem;
            font-weight: 800;
            color: green;
            font-family: "Barlow Medium", "Barlow", sans-serif;
        }

        .completed-item-container {
            display: flex;
            flex-direction: row;
            gap: .20vw;
            align-items: center;
        }
        .header-icon {
            background-color: transparent;
            padding-bottom: 15%;
        }
    }
}
