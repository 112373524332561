.city-info-item {
    margin: 1rem 2rem 1rem 2rem;
    display: flex;
}

.city-info-item-label
{
    font-weight: 600;
    flex: 0 0 8rem;
}

.city-info-link:visited {
    color: #000B57
}

@media (max-width: 800px) {
    .city-info-item-label
    {
        flex: 0 0 6rem;
    }
}