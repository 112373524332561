#ProfileGrid{
    .header-button {
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-weight: 600;
        font-size: 1.125rem;
        padding: 0.75rem;
        background-color: #040b50;
        color: #ffffff;
        border: solid #040b50;
        border-width: thin;
        border-radius: 5px;
        padding: .5rem 2rem;
        cursor: pointer;
    }
    .header-button-content {
        img {
            color:white;
            height: 1rem;
        }

        display: flex;
        align-items: center;
    }
    .header-button-label-text {
        padding-left: 1rem;
    }
    .profile-table-header {
        display:flex;
        font-size: 19px;
        font-weight:bold;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #f2f2f2;
        padding:1vw;
        border-top: 1px solid #797979;
        border-left: 1px solid #797979;
    }
    .no-data {
        padding-left: 4rem;
    }
    .fill-color {
        display: block;
        height: 40px;
        width: 40px;
        color: var(--color);
    }

    .profile-grid {
        display: grid;
        grid-area: header;
        grid-template-columns: repeat(var(--numberOfColumns), 1fr);
        margin-left: 4rem;
        margin-right: 4rem;
        min-width:fit-content;
        border-right: 1px solid #797979;
        border-bottom: 1px solid #797979;
    }

    .profile-table-cell{
        display:flex;
        padding:1vw;
        font-size: 19px;
        font-weight:500;
        justify-content: center;
        align-items: start;
        border-top: 1px solid #797979;
        border-left: 1px solid #797979;
        text-align:center;
        text-wrap: pretty;
    }
}