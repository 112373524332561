#BackgroundQuestions{
    width: 100%;
    display:flex;
    flex-direction: column;
    flex-grow:1;
    overflow:auto;

    h2 {
        font-family: "Barlow-Bold", "Barlow", sans-serif;
        font-size: 1.3rem;
        margin-bottom: 0;
        font-weight: 600;
        padding-bottom: 0.5rem;
    }

    .buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .single-button-container{
        padding-top: 1rem;
        padding-bottom: 1rem;
        width:100%;
    }

    .nonus-international-container{        
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Barlow Medium", "Barlow", sans-serif;
        font-size: 18px;
        font-weight:500;
        color:#040b50;
        flex-wrap: wrap;
        margin-top: 2rem;
        flex-direction: column;
    }
    .international-text {
        font-weight: 600;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 1.1rem;
        text-align: center;
    }
    .trs-international-button{
        justify-content: center;
        padding:.75rem;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-weight: 600;
        color:white;
        background-color: #000b57;
        border:none;
        border-radius: 5px; 
        text-align: center;   
        margin-top: 1.5rem;
        font-size: 1rem;
        width: 232px;
    }

    .questions-page-title{
        font-weight: bold;
        width: 100%;
    }

    .all-questions-container{
        display: flex;
        flex-direction: column;
        width:100%;
        border: 1px solid #293069;
        background-color: white;
        border-radius:10px;
        padding: 0.5rem 0rem;
        flex-grow: 1;
        overflow: auto;
    }

    .all-questions-container-scroll
    {
        overflow: auto;
        width: 100%;
        height: 100%;
        padding: 0.5rem;
    }    
    
    .trs-international-button:hover {            
        cursor: pointer;
    }
    

    .international-text {
        width: 90%;
    }

    @media (min-width: 801px) {
        background-color:  white;

        .questions-page-title {
            display: flex;
            flex-direction: column;
            border-bottom: .25em solid #040b50;
            margin-bottom: 1.5em;
        }

        h2 {
            font-family: "Barlow-Bold", "Barlow", sans-serif;
            font-size: 2em;
            margin: 0;

        }

        .all-questions-container{
            background-color: #f2f2f2;
        }

        .trs-international-button{
            font-size: 1.5rem;
            width: 354px;
        }

        .single-button-container {
            width: 100%;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }

        h2 {
            font-family: "Barlow-Bold", "Barlow", sans-serif;
            font-size: 2em;
            font-weight: bold;
            margin: 0;
        }
    }
}