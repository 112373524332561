#SignUpForm {
    display: flex;
    flex-direction: column;

    .signup-body-container {
        width: 100%;
        border:1px solid #000b57;
        border-top-left-radius: 10px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius:10px;
        margin: auto;
        height: 35rem;
        padding: 2rem;
        overflow-y: hidden;
        overflow-x: hidden;
    }
    
    .professionSelect{
        border: 1px solid #000b57;
        color: #000b57;
        font-size:1.2rem;
    }

    #leadsources, input[type=text], input[type=email] {
        height: 34px;
        width: 100%;
        font-size: 1.2rem;
        padding: 2px 2px 2px 5px;
        border-radius: 5px;
        border: 1px solid #000b57;
        background-color: #ffffff;
        box-sizing: border-box;
        font-family: "Barlow-Medium", "Barlow Medium", "Barlow", sans-serif;
        font-weight: 600;
        color: #000b57;
    }

    .signup-modal-field > label {
        display: block;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 18px;
        margin-bottom: 5px;
    }
    
    .signup-modal-field > input {
        height:2.125rem;
        width:100%;
        padding:.25rem;
        border:1px solid #000b57;
        font-size: 1.2rem;
    }
    
    .signup-body{
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        width:100%;
        font-weight: 600;
    }

    .policy-container{
        display: flex;
        align-items: start;
        padding: 20px;
        
        input[type=checkbox] {
            min-height: 1rem;
            min-width: 1rem;
        }
    }
    
    .policy-text{
        font-size: 14px;
        font-weight:300;
        text-wrap: wrap;
    }
    .signup-form-container{
        display: grid;
        grid-template-columns: repeat(2, 48%);
        gap: 1.5rem 4%;
    }
    
    .signup-form-input{
        display: flex;
        flex-direction: column;
    }
    
    
    .signup-modal-password{
        width: 100%;
        margin-right: 100px;
        padding: 2px 2px 2px 5px;
        border-radius: 5px;
        border: 1px solid #797979;
        background-color: #ffffff;
        box-sizing: border-box;
        font-family: "Barlow-Medium", "Barlow Medium", "Barlow", sans-serif;
        font-weight: 600;
        color: #000b57;
    }

    .signup-modal-validate-password{
        width: 100%;
        margin-right: 100px;
        padding: 2px 2px 2px 5px;
        border-radius: 5px;
        border: 1px solid #797979;
        background-color: #ffffff;
        box-sizing: border-box;
        font-family: "Barlow-Medium", "Barlow Medium", "Barlow", sans-serif;
        font-weight: 600;
        color: #000b57;
    }
    
    .alert {
        height: 1rem;
        width: 1rem;
        vertical-align: middle;
    }

    .invalid-field-alert-text {
        color: #E81111;
        font-style: italic;
        font-weight: 500;
    }

    @media(max-width: 800px){
        height:100%;

        .signup-body-container{
            padding: 1rem;
            height: 95%;
            overflow-y: scroll;
            margin:0;
        }

        .signup-form-container{
            display: grid;
            grid-template-columns: auto;
            width:80%;
            padding-left: 0;
            gap:1rem 2rem;
        }
    
        .signup-body{
            overflow-y: scroll;
            justify-content: start;
            align-items: center;
        }
        .signup-body{
            width: 100%;
            padding-bottom:1rem;
            overflow-x:hidden;
            overflow-y: scroll;
        }

        .signup-modal-field{
            width:100%;
        }
        
        .signup-modal-field > input{
            width:100%;
        }
    
    }
}