#hcp-references {
    display: flex;
    flex-grow: 1;
    overflow:auto;
    flex-direction: column;
    width: 100%;

    .reference-body{
        width:100%;
        overflow-x:hidden;
    }

    .emphasized-reference-information {
        text-decoration: underline;
    }

    .reference-component {
        display: flex;
        flex-direction: column;
    }
    .reference-components {
        display: flex;
        flex-direction: column;
        padding-top: 3rem;
        padding-bottom:3rem;
        gap: 3rem;
    }
    
    .reference-form-title {
        font-weight: 600;
        font-size: 20px;
        padding-bottom: 0.5rem;
    }

    @media (min-width: 801px) {
        background-color: white;

        .reference-component {
            max-width: 48%;
            min-width: 35rem;
            padding-bottom: 2rem;
        }

        .previous-next-button {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
            
        .emphasized-reference-information {
            font-weight: bold;
        }

        .reference-components {
            gap: unset;
            flex-wrap: wrap;
        }

        
        .reference-information {
            line-height: 2rem;
        }
      
        .reference-body{
            height: 100%;
            overflow:unset;
        }
        
        .reference-components {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
    }
}