#Application{
    width: 100%;
    display:flex;
    flex-direction: column;
    flex-grow:1;
    overflow: auto;
    background-color:  #f2f2f2;

    .title {
        display: flex;
        font-size: 1.25rem;
        font-weight: bold;
        border-bottom: .1em solid #040b50;
        padding-bottom:.25rem;
        width:100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
    }

    .yes-no-selected{
        background-color: #e9a91d;
        font-weight: 600;
    }

    input{
        font-size: 1.2rem;
        border: 1px solid #000b57;
        height: 2rem;
        color: #000b57;
        font-weight: 450;
        padding-left: 5px;
    }

    .p-inputtext {
        height: auto;
    }

    .application-checkbox {
        max-height: 1.1rem;
        min-width: 1.1rem;
        margin-bottom: unset;
        margin-left: 0px;
        border-radius: unset;
    }

    .application-helper-text {
        font-size: 1.2rem;
        font-style: italic;
        font-weight: 500;
        padding-bottom: 1.5rem;
        width: 100%;
    }

    .application-page-container{
        width:100%;
        scrollbar-gutter: stable;
        overflow: auto;
        padding: 1rem 1rem 1rem 1rem; 
    }

    .application-header {
        padding: 0 1rem;
        background-color: #f2f2f2;
    }

    .skills-title{
        font-family:  "Barlow Bold", "Barlow", sans-serif;
        font-size: 1.75rem;
        color:#000b57;
        font-weight:bold;
        padding-top:4rem;
        padding-bottom:.75rem;
    }

    @media (min-width: 801px) {
        background-color: #ffffff;
        .title{
            font-size: 2em;
            margin: 0;
        }

        .application-page-container, .application-header {
            background-color: white;
            width:100%;
            max-width: 100rem;
            align-self: center;
        }

    }
}