#DashboardOnboarding{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;

    .sign-contract-button {
        width: max-content;
        height: min-content;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        color: rgba(255, 255, 255, 0.99);
        box-sizing: border-box;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        background-color: rgba(4, 11, 80, 0.99);
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #040b50;
        cursor: pointer;
        align-self: center;
    }
    .page-container{
        display: flex;
    }

    .onboarding-body{
        display: flex;
        flex-direction: row;
        background-color:white;
        border: 1px solid #797979;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top:unset;
    }

    .offer-info{
        display: flex;
        flex-direction: column;
        gap:.5rem;
        flex-grow: 1;
        padding: 1rem 2rem;
    }

    .offer-info-header{
        font-size: 1.25rem;
        font-weight: bold;
    }

    .container-header{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: #135786;    
        font-size: 22px;
        font-weight: 600;
        color: white;
        padding:.75rem 1.5rem;
    }

    .container-body{
        padding:.5rem;
    }

    .days-until{ 
        font-style: italic;
        font-size: 20px;
    }
    

    .facility-image {
        height: 15rem;
        object-fit: cover;
        overflow: hidden;
    }

    .facility-name{
        font-size: 1.25rem;
        font-weight: 600;
    }

    .facility-address{
        font-size: 1.125rem; 
        font-weight: 500;
     }

    .duration-start{
       font-size: 1.125rem; 
       font-weight: 500;
       padding-bottom: 1.25rem;
    }

    .signed-contract-container{
        display: flex;
        width: 1rem;
        height: 3rem;
        cursor: pointer;
        align-items: center;
    }

    .pdf-icon{
        width: 3rem;
    }

    .pdf-label{
        font-weight: 500;
        color: #040b50;
        text-decoration: underline;
        
    }
    .sign-contract-button-container{
        justify-content: flex-end;
    }
    @media (max-width: 1250px) {
        .sign-contract-button-container {
            justify-content: center;
        }
        .pdf-icon{
            width: 2rem;
        }
        .pdf-label {
            font-size: 1.2rem;
        }
        .container-header{
            padding: 0px 1rem;
            flex-direction: column;
            align-content: center;
            text-align: center;
            font-size: 20px;
        }

        .offer-info-header{
            padding-bottom: 1.25rem;
        }
        .onboarding-body{
            flex-direction: column;
            justify-content: center;
        }
        .days-until{
            align-self: center;
            font-size: 1.25rem;
        }

        .offer-info{
            flex-grow: unset;
            padding: 1rem 1rem;
        }

        .facility-name{
            font-weight: 500;
            font-size: 1rem;
        }
        .facility-address{
            padding-bottom: 1.25rem;
        }

        .signed-contract-container{
            width: unset;
            display: flex;
            align-items: center;
            gap: .5rem;
        }
    }


}