.start-application-button {
    width: 100%;
    padding: .5em;
    border-radius: 5px;
    border: 1px solid #000b57;
    background-color: #000b57;
    box-sizing: border-box;
    font-family: "Barlow SemiBold", "Barlow", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: #ffffff;
    text-align: center;
    line-height: normal;
    cursor: pointer;
}

.is-interested {
    width: 100%;
    padding: 0.5em;
    border-radius: 5px;
    border: 1px solid #43a047;
    background-color: #43a047;
    box-sizing: border-box;
    font-family: "Barlow SemiBold", "Barlow", sans-serif;
    font-size: 1rem;
    color: #ffffff;
    text-align: center;
    line-height: normal;
}

.start-application-button-container {
    width: 100%;
    max-width: 20rem;
}

@media (min-width: 801px) {
    .start-application-button-container {
        max-width: 15rem;
    }

    .start-application-button{
        font-size: 1.2rem;
        width: 100%;
    }

    .is-interested {
        font-size: 1.2rem
    }   
}
