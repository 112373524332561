#ApplyNow {
    width: 100vw;
    height: 100vh;
    background-color: #f2f2f2;
    flex-grow:1;


    .application-header{
        height: 4rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: rgb(245, 252, 253);
        font-weight: bold;
        font-size: 1.5rem;
        border-top: 0.0625rem solid #797979;
        border-bottom: 0.0625rem solid #797979;
        padding: 0.125rem 0.125rem 0.125rem 15%;
    }
    .greeting {
        font-weight:600;
        width: 100%;
        padding-left: 15%;
        font-size:1.125rem;
    }

    .body-container{
        width: 57%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        overflow-y: auto;
    }

    .inline-container{
        display: flex;
        align-items: center;
        flex-direction: row;
        font-family: "Barlow Bold", "Barlow", sans-serif;
        justify-content: start;
        width: 100%;
        padding-left:2.688rem;
        padding-right:1rem;
    }
    
    .left-container{
        display: flex;
        align-items: center;
        width:40%;
        gap:5%;
    }

    .right-container{
        display: flex;
        width:60%;
    }
    hr{
        margin: 0 1.5rem;
    }

    .round-corners{
        background-color: rgba(254, 255, 254, 0.99);
        border-radius: 10px;
        border: 1px solid  #797979;;
    }

    .step-description {
        flex-grow: 1;
        margin-left: 50px;
    }

    .step-name {
        font-size: 1.25rem;
        font-weight: 600;
    }

    .large-number{
        font-size: 3rem;
        font-weight: bold;
        padding: 0.4em 0;
        color:#969696;
        width:10%;
        display: flex;
        justify-content: center;
    }

    .get-started-button {
        width: 20rem;
        justify-content: center;
        padding: .5rem;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 1.313rem;
        font-weight: 600;
        color: white;
        background-color: #000b57;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
    .apply-now-button-container {
        padding: 3% 0%;
        display: flex;
        flex-direction: row;
        justify-content:flex-end
    }

    @media(max-width: 800px){

        .body-container{
            width: 100%;
        }

        .application-header{
            padding: 0.125rem 0.125rem 0.125rem 1rem;
        }

        .left-container{
            display: flex;
            width:50%;
        }
        .right-container{
            display: flex;
            justify-content: start;
            width:50%;
        }
        .step-name{
            font-size: .875rem;
        }
        .apply-now-button-container{
            justify-content: center;
        }
        .inline-container{
            width:100%;
            gap:1rem;
            padding-left:1.25rem;
        }
        
        .body-container{
            width: 90%;
        }

        .step-description{
            font-weight: unset;
            font-size:0.875rem;
            margin-left: unset ;
        }

        .large-number{
            padding: 0.4em 0.5em;
        }

        .greeting {
            width: unset;
            padding-left: 1rem;
        }
    }

}