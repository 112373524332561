#ProfileGridMobile {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    
    .header-button {
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-weight: 600;
        font-size: 1.125rem;
        padding: 0.75rem;
        background-color: #040b50;
        color: #ffffff;
        border: solid #040b50;
        border-width: thin;
        border-radius: 5px;
        padding: .5rem 2rem;
        cursor: pointer;
    }
    .header-button-content {
        img {
            color:white;
            height: 1rem;
        }

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .header-button-label-text {
        padding-left: 1rem;
    }
    .containerHeader{
        display: flex;
        background-color: #D2DCF3;
        justify-content: center;
        padding:1rem;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color:#000b57;
        font-weight: 550;
        font-size: 1.25rem;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid #797979;
    }
    .no-data {
        padding: 15px;
        text-align: center;;
    }
    .parentContainer{
        background-color: white;
        border-radius: 10px;
        border: 1px solid #797979;
        .parentContainerHeader{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            background-color: #135786;
            color: #fff;
            font-weight: bold;
            border-radius: 0;
            text-overflow: ellipsis;
            text-align: center;
            font-size: 18px;
            border-radius: 10px 10px 0 0;
            border: 1px solid #797979;
            
        }
    }
    .containerItems{
        background-color: white;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
        padding: 10px 20px;
    }
    .containerWrapper {
        border: 1px solid #797979;
        background-color: #D2DCF3;
        border-radius: 7px;
    }
    .containerData{
        padding:15px;
        
    }
    .itemHeader{
        font-size: 1rem;
        color: #000b57;
        font-weight: 650;
    }
    .itemValue:not(:last-child) {
        padding-bottom: 15px;
    }
    .itemValue:last-child {
        padding-bottom: 5px;
    }
    li {
        list-style: none;
    }
}