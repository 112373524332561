
#Dashboard {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-grow: 1;
    background-color: #e1e1e1;
    height: 100%;

    .page-container {
        display: flex;
        flex-direction: column;
        gap:1.5rem;
        align-self: center;
        background-color:  #e1e1e1;
        flex-grow: 1;
        width: 100%;
        padding: 1rem 11.5rem;
    }

    //unsets absolute position on multiselect wrapper which caused styling issues
    .p-hidden-accessible {
        position: unset;
    }
    
    .job-search {
        padding: 3% 8.125rem 0px 8.125rem;
        background-color: rgba(255, 255, 255, 0);
    }
    
     body {
        background-color: #e1e1e1;
    }
    
    .search-for-jobs-header {
        border: 1px solid rgba(121, 121, 121, 1);
        background-color: #135786;
        border-radius: 12px 12px 0px 0px; 
        top: 1.2rem;
        position: relative;
        color: white;
        font-weight: bold;
        font-size: 1.2rem;
        min-width: 18rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        padding-left: 1rem;
    }
    
    .application-status {
        display: flex;
        flex-direction: column;
    }
    
    .application-status-header {
        justify-content: space-between;
        border: 1px solid rgba(121, 121, 121, 1);
        background-color: #135786;
        border-radius: 12px 12px 0px 0px; 
        top: 1.2rem;
        color: white;
        font-weight: bold;
        font-size: 1.2rem;
        min-width: 18rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        padding-left: 1rem;
    }
    
    .steps-complete-text {
        font-style: italic;
        margin-right: 1rem;
    }
    
    .dashboard-application-status-container {
        background-color: rgba(253, 253, 253, 0.705);
        border: 1px solid  #797979;
        border-radius: 10px;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        padding: 2rem;
    }

    .received-container {
        background-color: #f2f2f2;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 600;
        font-size: 1.25em;
        .header-1{
            font-weight: 700;
            padding-bottom: 1rem;
        }
    }
    .status-container-textbox{
        padding: 1rem;
    }
    

    .application-status-content {
        border: 1px solid #040b50;
        border-radius: 10px;
        background-color:  rgba(254, 255, 254, 0.99);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    
    .search-for-jobs-header p {
        justify-content: left;
        min-width: 18rem;
        padding-left: 3rem;
    }
    
    
    .resume-button-container {
        display: flex;
        align-items: end;
        width: 100%;
        justify-content: right;
        margin-top:1.5rem;
    }
    
    .resume-button{
        cursor: pointer;
        background-color: #040b50;
        width: 100%;
        max-width: 400px;
        float: right;
        padding: 0.6rem 2.5rem;
        border-radius: 5px;
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #ffffff;
        text-align: center;
        line-height: normal;
        border: none;
        font-size: 20px;
    }

    .empty-container {
        height: 9rem;
    }

    .p-button {
        position: unset;
    }

    .search-for-jobs-container{
        display: flex;
        flex-direction: column;
        gap:1rem;
    }
    
    @media (max-width: 1251px) {

        .empty-container {
            height: 8rem;
        }

        .page-container {
            padding:unset;
            background-color:  #f2f2f2;
            gap:unset;
        }

        .green-check-img {
            padding-left: 1rem;
        }

        .search-for-jobs-header{
            background-color: transparent;
            color: #000B57;
            border: none;
            font-size: 20px;
            padding-left: 1rem;
            
        }
    
        .search-for-jobs-header p{
            padding-left: 5px;
        }
    
        .dashboard-application-status-container {
            background-color: rgba(253, 253, 253, 0.705);
            border: 1px solid  #797979;
            border-top-left-radius: unset;
            border-top-right-radius: unset;
            padding: unset;
        }

        
    
        .application-status-content {
            border: none;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-left-radius: unset;
            border-top-right-radius: unset;
            background-color:  rgba(254, 255, 254, 0.99);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 1rem;
        }
    
        .job-search{
            padding: 0px 0px 0px 0px;
            overflow-y: scroll;
        }
    
        body{
            overflow-y:hidden;
        }
    
        .steps-complete-text {
            display: none;
        }
    
        
        .application-status-header {
            justify-content: center;
            padding-left: 0rem;
        }

        .resume-button-container {
            padding: 1rem;
        }
    }
}