.reference-form-container {
    display: flex;
    flex-direction: column;
    border:1px solid #000b57;
    border-radius:7px;
    padding:30px 20px;
    background-color: white;

    label{
        font-size: large;
        font-weight: 500;
    }

    .ref-radio-collection{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 2rem;        
    } 

    .radio-buttons {
        display:flex;
        flex-direction: column;
        justify-content: flex-end;
        line-height: 1.5rem;
    }

    .input-radio {
        display: flex;
        align-items: center;
    }

    input[type='text'] {
        height: 2.0rem;
        border:1px solid #000b57;
        color: #000b57;
        font-size: 1.2rem;
        border-radius: 5px;
        padding-left: 5px;
    }

    select{
        font-size: 1.2rem;
        border-radius: 5px;
        height: 2rem;
        border:1px solid #000b57;
        background-color: white;
        color: #000b57;
        font-weight: 450;
        width: 100%;
    }
    
    label, .ref-radio-collection {
        margin-top:1.5rem;
        
    }

    label:first-of-type{
        margin-top:0px
    }

    @media (min-width: 801px) {
      
        background-color: #f2f2f2;
        label:not(:first-of-type) {
            margin-top:1.5rem;
        }

        .ref-radio-collection {
            justify-content: unset;
            padding-right: 0;
            display: flex;
            flex-direction: column;
        }
    }
}