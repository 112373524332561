#identification-info{
    display: flex;
    flex-grow: 1;
    overflow:auto;
    flex-direction: column;
    background-color:  #f2f2f2;


    .title h2 {
        font-family: "Barlow-Bold", "Barlow", sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 0;
        padding-bottom: 0.5rem;
    }

    p{
        font-style: italic;
        width: 100%;
        margin-top: 0px;
        font-weight: 550;
    }

    .social-security-text {
        padding-top: 1rem;
        font-size: 1.1250em;
    }

    .why-question-text {
        font-weight: 550;
    }

    label {
        font-weight: 550;
        padding-bottom: 0.5rem;
        font-size: 1.1250em;
        width: fit-content;
    }

    .form-container{
        display: flex;
        flex-direction: column;
        border: 1px solid #293069;
        background-color: white;
        border-radius:10px;
        padding: 2rem 2.5rem;
        overflow: auto;
        gap: 1rem;
    }

    .social-security-container {
        display: flex;
        flex-direction: column;
        padding-bottom: 1.5rem;
    }

    .social-security-input {
        width:16.5rem;
        height: 2.5rem;
    }

    input{
        font-size: 1.2rem;
        border: 1px solid #000b57;
        height:2.5rem;
        color: #000b57;
        font-weight: 450;
    }

    .dob-container{
        display: flex;
        flex-direction: column;
    }

    .dob-input {
        display: flex;
        justify-content: left;
    }

    .two-digit-input {
        width:3.5rem;
        margin-right: 0.5rem;
        text-align: center;
    }

    .four-digit-input {
        width:5rem;
        text-align: center;
    }

    .p-calendar {
        position: unset !important;
    }

    .p-button {
        position: unset !important;
    }
    
    /*Desktop*/
    @media (min-width: 801px) {
        background-color:  white;

        h2 {
            font-family: "Barlow-Bold", "Barlow", sans-serif;
            font-size: 2em;
            font-weight: 600;
            margin: 0;
        }

        .previousNextButtonHeader {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-top:1.5rem;
            padding-bottom:1.5rem;
            justify-content: space-between;
        } 

        .form-container {
            display: flex;
            flex-direction: row;
            justify-content: start;
            background-color: #f2f2f2;
            flex-wrap: wrap;
            width: 100%;
        }

        .social-security-container {
            padding-bottom: unset;
        }

    }
}