#CertificationItem{
    .upload-container{
        display: flex;
        justify-content: space-between;
        gap:.5rem;
        align-items: end;
        width:100%;
    }
    .file-picker-container{
        cursor: pointer;
    }

    .upload-file-container{
        display: flex;
        gap:.5rem;
        align-items: center;
    }
    .remove-file-button{
        color: red;
        font-weight: 550;
        cursor: pointer;
        font-size: 19px;
    }
    .file-name{
        max-width: 40vw;
        width:fit-content;
        color:#040b50;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: underline;
        font-family:  "Barlow Medium", "Barlow", sans-serif;
    }
    .upload-button{
        display:flex;
        width:7rem;
        height:2.3rem;
        font-size: 20px;
        border: 1px solid black;
        border-radius:5px;
        justify-content: center;
        align-items: center;
    }
    .upload-icon{
        object-fit:cover;
        width:25px;
    }
    
    .file-picker{
        overflow: hidden;
        width:0;
    }

    .certification-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
    }

    .delete-record-button{
        display: flex;
        justify-content: space-around;
        height: 2.5rem;
        padding-top: 2px; 
        border-radius: 5px;
        font-size: 1.1rem;
        font-weight: 600;
        border-style: solid;
        cursor: pointer;
        border-color: #fa0000;
        color: #fa0000;
        background-color: #ffffff;
        align-items: center;
        min-width: auto;
    }

    .delete-record-text {
        min-width: fit-content;
    }

    .delete-record-button:disabled{
        background-color: #ffffff;
        color: #cfcfcf;
        border-color: rgb(102, 100, 100);
        border-width: 1px;
        cursor:default;
    }

    .delete-record-button-container{
        display: flex;
        justify-content: left;
        padding-bottom: 1rem; 
        width: 49%;
    }

    .save-record-button{
        height: 2.5rem;
        padding-top: 2px; 
        border-radius: 5px;
        font-weight: 600;
        border-color: #040b50;
        color:white;
        background-color: #040b50 ;
        border-style: solid;
        cursor: pointer;
        font-size: 18px;
    }

    .save-record-button:disabled{
        background-color: #9c9eb2;
        color: #cfcfcf;
        border-width: 1px;
        cursor:default;
    }

    .save-record-button-container{
        display: flex;
        justify-content: right;
        width:49%;
        padding-top: 1rem; 
    }

    .verified-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        color:#4caf50;
        font-weight: 600;
        padding-top: 1.5rem;
        font-size: unset;
    }

    .verified-text-checkmark {
        height: 2rem;
        width: 2rem;
        margin-right: 0.4rem;
    }

    .record-header-text {
        font-weight: 600;   
        font-size:1.25rem;
        display: flex;
        align-items: center;
    }

    .verified-header-checkmark {
        height: 1.25rem;
        width: 1.25rem;
        margin-left: 0.4rem;
    }
    
    @media (min-width: 801px) {
        .file-name{
            max-width: 100%;
        }

        .verified-text {
            font-size: 1.25em;
        }

        .file-name {
            width: 20vw;
        }

        .save-record-button{
            width: 11rem;
        }
    }

}