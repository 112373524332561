
#ReviewAndSignContract {
    //overflow: auto;
    overscroll-behavior: none !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow:1;
    overflow:auto;

    
    label {
        font-size: 1.5rem;
        font-weight: 600;
    }
    
    .images-container {
        display: flex;
        flex-direction: column;
        overflow: auto;
        gap: 2rem;
        flex-grow:1;
        padding-bottom: 2rem;
    }

    .image-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            max-width: 80%;
            box-shadow: 5px 5px 7px gray;
            border: 1px solid gray;
        }
    }
    
    .document{
        cursor: pointer;
        text-decoration:underline;
        font-weight: 600;
        color:#040b50;
        width: 100%;
    }

    .checkbox-container {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .agree-checkbox {
        padding-top: 1px;
        min-width: 2rem;
        min-height: 2rem;
        margin-top: 0.35rem;
    }
    .loadingOverlayContainer {
        width: 100%;
        height: calc(96vh - 3em);
        overflow: hidden;
        background-color: rgba(0,0,0,.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        position: absolute;
        border-radius: 0px !important;
    }
    .loadingOverlaySubContainer {
        background-color: white;
        border-radius: 0px !important;
    }

    .page-container {
        display: flex;
        flex-direction: column;
        gap:1.5rem;
        align-self: center;
        background-color:  #e1e1e1;
        overflow:auto;
        flex-grow: 1;
        width: 100%;
    }

    .button-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding-top:1rem;
    }

    .footer {
        height:7rem;
        padding-top: 1rem;
    }

    .sign-contract-button {
        width: max-content;
        height: min-content;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        color: rgba(255, 255, 255, 0.99);
        box-sizing: border-box;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        background-color: rgba(4, 11, 80, 0.99);
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #040b50;
        cursor: pointer;
    }

    .sign-contract-button:disabled {
        color: #cfcfcf;
        background-color: #9c9eb2;
        cursor: unset;
        border: 1px solid #e1e1e1;
    }

    .view-contract-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }

    .view-contract-button {
        margin-top: 2rem;
        padding: 0.5rem 4rem;
        border-radius: 5px;
        color: rgba(255, 255, 255, 0.99);
        box-sizing: border-box;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        background-color: rgba(4, 11, 80, 0.99);
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #040b50;
        cursor: pointer;
        text-wrap: nowrap;
    }

    @media (max-width: 1249px) {

        overflow: unset;

        hr{
            width: 100%;
            border: 1px solid black;
        }

        .checkbox-header-container{
            display:flex;
            gap:2rem;
            align-items: center;
    
            label{
                font-size: 20px;
                font-weight: 600;
            }
        }

        .images-container {
            gap:.25rem;
            padding-bottom: 5rem;
        }
    
        .image-wrapper {
            img {
                min-width: 100%;
                box-shadow: unset;
            }
        }

        .button-container {
            padding-top: 0rem;
            gap: .5rem;
        }

        .checkbox-container {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .application-checkbox {
            max-height: 1.1rem;
            min-width: 1.1rem;
            margin-bottom: unset;
            margin-left: 0px;
            border-radius: unset;
        }

        .disabled-text
        {
            color: grey;
        }

        .agree-checkbox {
            min-width: 2rem;
            min-height: 2rem;
        }
    
        .page-container {
            display: flex;
            flex-direction: column;
            gap:1.5rem;
            align-self: center;
            background-color:  #e1e1e1;
            flex-grow: 1;
            width: 100%;
            padding: 1rem;
            height: 100vh;
        }

        .sign-contract-button {
            width: 100%;
        }
    }
}