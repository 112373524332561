.closed-header-container {
    display: flex;
    align-items: center;
    height:2rem;
    gap:2rem;
    font-weight:550;
    padding-left: .5rem;
}

.alert-container{
    display:flex;
    align-items: center;
    padding: .5rem;
    gap:.3rem;
}

.alert-image{
    height:26px;
}

.alert-text {
    display: flex;
    flex-direction: row;
    font-size:18px;
    font-weight:600;
    color: #283c98;
    gap:.4rem;
}

@media (max-width: 800px) {
    .alert-container{
        display:flex;
        align-items: center;
        padding-left: .5rem;
    }

    .closed-header-container {
        gap: 0;
        padding: 0 0.5rem;
        justify-content: space-between;
        padding-right: 1rem;
        width: 100%
    }

}

@media (max-width: 375px) {
    .closed-header-container {
        font-size: 0.95rem
    }
}

@media (max-width: 360px) {
    .closed-header-container {
        font-size: 0.9rem
    }
}