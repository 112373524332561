#ErrorMessage{
    display: flex;
    gap: .25rem;
    padding-top: .25rem;
    align-items: center;
    
    .error-message{
        color: red;
        font-size: 12px;
        font-weight: 550;
        font-style: italic;
    }

    .error-image{
        height: 1rem;
        width: 1rem;
    }
}