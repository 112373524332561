#JobSearchCard{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    
    a{
        color:#000B57;
        text-decoration: none;
    }

    .archived{
        display: flex;
        flex-direction: column;
        border-radius: inherit;
        color:grey;
    }

    .selected{
        display: flex;
        flex-direction: column;
        border: 4px solid #040b50;
        border-radius: inherit;
        background-color: #f2f2f2;
    }

    .unselected{
        display: flex;
        flex-direction: column;
        border: 1px solid #040b50;
        border-radius: inherit;
        background-color: white;
    }

    .closeMatch{
        display: flex;
        justify-content: center;
        align-items: center;
        padding:.10rem;
        background-color: #fff495;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom:1px solid #040b50;
        font-weight:bold;
        color:black;
    }

    .exactMatch{
        display: flex;
        justify-content: center;
        align-items: center;
        padding:.10rem;
        background-color: #ccffcc;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom:1px solid #040b50;
        font-weight:bold;
        color:black;
    }

    .top-data{
        display: flex;
        flex-direction: column;
        padding:.5rem;
        gap:.25rem;
    }

    .shift-container{
        display: flex;
        gap:.25rem;
        align-items: center;
    }

    .shift-icon{
        height:1.25rem;
    }

    .bottom-data{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top:1px solid #040b50;
        font-size: 18px;
    }

    .location-data-container{
        display: flex;
        flex-direction: column;
        padding:.25rem;
    }

    .pay-style{
        padding: .25rem;
    }

    .miles-from-location{
        font-size: .833rem;
        font-style: italic;
    }

    .match-highlight{
        background-color:#fff495;
        border-radius: 5px;
        width:fit-content;
    }

    .bold{
        font-weight: bold;
    }

    .featured{
        color: #ffb25a;
        font-style: italic;
        font-weight: bold;
        display: flex;
        justify-content: flex-start;
    }

    .title-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: .7rem;
    }
    
    .detail-icon {
        margin-left: 0.5rem;
        margin-right: 0.75rem;
        width: 1.5rem;
        display: flex;
        justify-content: flex-start;
      }

    @media (max-width: 1250px) {

        .selected{
            background-color: white;
        }

        .unselected{
            background-color: white;
            margin:unset;
        }

        .title-container{
            padding: unset;
        }

        .top-data{
            padding: .15rem .5rem
        }
    }
}