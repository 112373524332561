#DashboardSubmission{
    display: flex;
    flex-direction: column;

    .offer-info-banner {
        background-color:  #43a047;
        color:#ffffff;
        display: flex;
        text-align: center;
        padding: 3px 10px;
        width: fit-content;
        border-radius: 5px;
        font-size: 1rem;
    }

    .clock-icon {
        margin-right: 0.75rem;  
        width: 1.3rem;
        height: 1.3rem;

    }

    .people-icon {
        margin-right: 0.75rem;
        width: 1.5rem;
        height: 1.5rem;
    }

    .dollar-icon {
        margin-right: 0.75rem;
        width: 1.5rem;
        height: 1.5rem;
    }

    .dashboard-submission-header{
        display: flex;
        flex-direction: row;
        background-color: #000b57;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-color: #797979;
        min-height: 3.5rem;
    }

    .dashboard-submission-offer-info{
        display: flex;
        gap: .5rem;
        font-style: "Barlow";
        font-size: "20px";
        color: #ffffff;
        padding: .25rem 1rem;
        justify-content: center;
        align-items: center;
        
    }

    .dashboard-submission-offer-chkmrk{
        width: 3rem;
        height: 3rem;
    }

    .dashboard-submission-body{
        display: flex;
        flex-direction: row;
        height: min-content;
    }
    
    .offer-info {
        display: flex;
        flex-direction: column;
        gap:.5rem;
        flex-grow: 1;
        height: min-content;
        // background-color: #f2f2f2;
        background-color: white;
        border: solid 1px #040b50;
        border-radius: 10px;
    }

    .offer-info-header{
        font-style: "Barlow";
        font-size: 27px;
    }
    .facility-info{
        font-style: "Barlow";
    }

    .data-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 0.5rem;
        column-gap: 2rem;
    }

    .row{
        display: flex;
        flex-direction: row;
        font-weight: 600;
    }

    .detail-icon {
        margin-right: 0.75rem;  
        width: 1.5rem;
        height: 1.5rem;
    }

    .facility-image {
        width: 20rem;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        border-bottom-left-radius: 10px;        
    }

    .button-row {
        height: 4rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 1rem;
    }

    .view-details-button {
        width: max-content;
        height: min-content;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.99);
        box-sizing: border-box;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        color: rgba(4, 11, 80, 0.99);
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #040b50;
        cursor: pointer;

    }

    .offer-top{
        display: flex;
        flex-direction: column;
        gap:.5rem;
        padding:1rem;
    }

    .offer-info-location{
        display: flex;
        border-top: 1px solid #797979;
        padding:1rem;
        font-weight:bolder;
    }

    .offer-info-shift{
        display: flex;
    }

    .offer-info-shift-display-name{
        margin-left: -8px;
    }

    .offer-info-title{
        font-weight: bolder;
        font-size: 1rem;
    }

    @media(min-width: 1251px) {
        .offer-info {
            padding:1rem;
            border:unset;
            border-radius:unset;
            border-bottom: solid 1px #040b50;
            border-right: solid 1px #040b50;
            border-bottom-right-radius: 10px;
            background-color: #f2f2f2;
        }

        .job-number{
            font-size: 18px;
        }

        .offer-info-header{
            font-weight: bold;
        }

        .facility-info{
            font-weight: bold;
        }
    }
}