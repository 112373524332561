.filterContainerMobileBackground {
    width: 100%;
    height: 100dvh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.filterContainerMobileRoot {
    z-index: 2;
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 1px solid rgba(121, 121, 121, 1);
    border-radius: 0;
    padding: 1rem 1rem 2rem 1rem;
    background-color: rgba(255, 255, 255, 1);
}

.searchButton {
    flex-grow: 1;
    justify-content: center;
}
