#JobOfferDetails {
  display: flex;
  flex-grow: 1;
  overflow: auto;

  .right-pane-logged-in {
    border: 1px solid #797979;
    border-radius: 10px;
    background-color: white;
    width: 100%;
    overflow: hidden;
    height: 82.4vh;
  }

  .right-pane-with-secondary-header {
    display: flex;
    flex-direction: column;
    border: 1px solid #797979;
    border-radius: 10px;
    flex-grow: 1;
    overflow: auto;
    position: relative;
  }

  .jobSearchDetailsRoot {
    display: flex;
    flex-direction: column;
  }

  .jobDetailListStyle {
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    overflow: auto;
    flex-direction: column;
  }

  .archived{
    color: #7D7D7D;
  }
  
  .rightPaneItem {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding-left: 2rem;
    flex-flow: wrap-reverse;
    align-items: start;
  }
  .rightPaneDetails {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .detail-icon {
    margin-left: 0.5rem;
    margin-right: 0.75rem;
    width: 1.5rem;
  }
  .facilityImageContainer {
    position: relative;
    width: 13.75rem;
    height: 11.5rem;
    overflow: hidden;
  }
  .facilityImage {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .jobMainHeader {
    padding: 1rem 0 0.5rem 2rem;
  }
  .jobMainHeaderTitle {
    font-size: 2rem;
    font-weight: bold;
  }
  .jobMainHeaderSeparator {
    font-size: 2rem;
    font-weight: bold;
  }
  .jobMainHeaderSubtitle {
    font-size: 2rem;
    font-weight: bold;
  }
  .jobMainHeaderJobNumber {
    padding-left: 2rem;
    padding-bottom: 0.5rem;
  }
  .jobMainHeaderJobNumberText {
    font-size: 1.25rem;
    font-weight: 600;
  }
  .detail-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .detail-title-text {
    margin: 0;
  }
  .detail-subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .detail-subtitle-text {
    margin: 0;
  }
  .detail-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
  }
  .detail-item-text {
    margin: 0;
  }
  .detail-item-text-positions {
    margin: 0 0.5rem 0 0;
  }
  .detail-item-with-helper-text {
    display: flex;
    flex-direction: column;
  }
  .helper-text {
    font-style: italic;
    background-color: #f2f2f2;
    padding: 0.25rem;
    border-radius: 5px;
    margin-left: 2.75rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    font-size: 14px;
    width: fit-content;
  }
  .header {
    background-color: #e1e1e1;
    box-sizing: border-box;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    font-family: "Barlow Bold", "Barlow", sans-serif;
    color: #000b57;
    text-align: left;
    font-size: 20px;
    padding: 0.5rem 2rem 0.5rem 2rem;
    margin-top: 1rem;
  }
  .bold {
    font-weight: bold;
  }
  .mobile-navigation-bar {
    display: none;
  }
  .buttonRow {
    display: flex;
    justify-content: end;
    margin-right: 2rem;
    margin-top: 2rem;
    gap: 1.5rem;
  }
  
  .hide {
    display: none;
  }

  .sign-contract-button-container {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1rem;
    padding-right: 1rem;
}

.sign-contract-button {
    width: max-content;
    height: min-content;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.99);
    box-sizing: border-box;
    font-family: "Barlow SemiBold", "Barlow", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    background-color: rgba(4, 11, 80, 0.99);
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #040b50;
    cursor: pointer;
    align-self: center;

}
  
  @media (max-width: 1250px) {
    display: flex;
    top: 0;
    left: 0;
    width:100dvw;
    height: 100dvh;
    position:absolute;

    .sign-contract-button-container {
      padding-top: 1rem;
      justify-content: center;
    }

    .contact-recruiter-footer {
      border-radius: unset;
    }
    
    .rightPaneItem {
      flex-direction: column-reverse;
      padding: 1rem 0 0 0.5rem;
    }

    .right-pane-with-secondary-header {
      position:relative;
      border: unset; 
      border-radius: unset;
    }

    .jobMainHeader {
      background-color: #135786;
      color: white;
      margin-top: 0.5em;
      padding: 0.5rem 0.5rem 0.5rem 1rem;
    }
    .jobMainHeaderJobNumber {
      background-color: #135786;
      color: white;
      padding-left: 1rem;
      padding-top: 0.25rem;
      padding-bottom: 0.75rem;
    }
    .jobMainHeaderJobNumberText {
      font-size: 1rem;
    }
    .jobMainHeaderTitle {
      font-size: 1.5rem;
    }
    .jobMainHeaderSeparator {
      display: none;
    }
    .jobMainHeaderSubtitle {
      font-size: 1.25rem;
      display: block;
      padding-top: 0.5rem;
    }
    .mobile-navigation-bar {
      display: flex;
      padding-top: .5em;
      font-weight: bold;
      align-items: center;
      padding-left: 1em;
    }
    .facilityImageContainer {
      display: none;
    }
    .facilityImage {
      display: none;
    }
    .returnToResults {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 18px;
      color:#000B57;
    }
    .jobDetailListStyle {
      padding-bottom: 5rem;
    }
    .header {
      padding-left: 1rem;
    }
    .buttonRow {
      width:100%;
      margin-right: 0px;
      padding: 0 1rem;
      align-items: center;
      flex-direction: column;
      gap:1rem;
    }
  }
}
