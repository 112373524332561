#education-info {
    background-color:  #f2f2f2;
    width: 100%;
    display:flex;
    flex-direction: column;
    flex-grow:1;
    overflow:auto;
    
    .new-record-button{
        width: 11rem;
        height: 2.4rem;
        padding: 2px; 
        border-width: thin;
        color: #040b50;
        border-radius: 5px;
        font-weight: 600;
        border-color: #040b50;
        border-style: solid;
        cursor: pointer;
        background-color: white;
        min-width: fit-content;
    }
    .new-record-button:disabled{
        color: #cfcfcf;
        border-color: dimgray;
        cursor:default;
        border-width: 1px;
    }
    .new-record-button-content{
        display:flex;
        flex-direction: row;
        justify-content: space-evenly;
        font-size:20px;
    }

    .new-record-button-content img {
        width: 1rem;
        height: 1rem;
        margin-top: auto;
        margin-bottom: auto;
    }

    .education-content{
        padding-bottom: 1rem;
    }
    .record-container{
        background-color: white;
        border-radius: 7px;
        border:1px solid #000b57;
        display: flex;
        flex-direction: column;
        justify-content:  center;
        padding-right: 2rem;
        padding-left: 2rem;     
        margin:1px;     
    }
    
    .education-info-record-header-text {
        font-weight: 600;   
        font-size:1.25rem;
        display: flex;
        align-items: center;
    }

    .education-info-page-container{
        width:100%;
        scrollbar-gutter: stable;
        overflow: auto;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    .education-info-list {
        display: flex;
        flex-direction: column-reverse;
    }
    label{
        display: block;
        padding-bottom:0.3rem;
        font-weight: 550;
    }
    .graduation-label{
        padding-bottom:0.3rem;
        font-weight: 550;
    }
    .education-row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 6%;
    }
    .form-element {
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        font-weight:500;
        font-size:18px;
    }
    .select-box {
        width:14rem;
        height: 2rem;
        border-radius: 5px;
        border: 1px solid #000b57;
        font-weight: 450;
        color: #000b57;
        font-size: large;
        background-color: white;
    }
    .input-box{
        width:14rem;
        border: 1px solid #000b57;
        height: 2rem;
        font-weight: 450;
        font-size: large;
        color: #000b57;
    }
    .graduation-input-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
    .month-input-box{
        width:70px;
        border: 1px solid #000b57;
        height: 2rem;
        text-align: center;
        font-weight: 450;
        font-size: large;
        color: #000b57;
    }
    .year-input-box{
        width:90px;
        border: 1px solid #000b57;
        height: 2rem;
        text-align: center;
        font-weight: 450;
        font-size: large;
        color: #000b57;
    }

    .saved-record-container{
        background-color: #f1fafc;
        border-radius: 7px;
        border:1px solid #000b57;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem;
        font-size: 20px;
        margin:1px;
    }
    .saved-degree-type-and-field{
        font-weight: 700;  
    } 
    .saved-education-info-record-header-text {
        font-weight: 600;
        font-size: 20px;   
    }
    .save-record-button{
        width: 11rem;
        height: 2.5rem;
        padding-top: 2px; 
        border-radius: 5px;
        font-size:20px;
        font-weight: 600;
        border-color: #040b50;
        color:white;
        background-color: #040b50 ;
        border-style: solid;
        cursor: pointer;
    
    }
    .verified-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        color:#4caf50;
        font-weight: 600;
        padding-top: 1.5rem;
        font-size: 0.9em;
    }
    .verified-header-checkmark {
        height: 1.2rem;
        width: 1.2rem;
        margin-left: 0.4rem;
    }

    .verified-text-checkmark {
        height: 2rem;
        width: 2rem;
        margin-right: 0.4rem;
    }
    .save-record-button:disabled{
        background-color: #9c9eb2;
        color: #cfcfcf;
        border-width: 1px;
        cursor:default;
    }
    .save-record-button-container{
        padding-top: 1rem;
        width: 49%;
        display: flex;
        justify-content: right;
    }

    .delete-record-button{
        width: 11rem;
        height: 2.5rem;
        padding-top: 2px;
        border-radius: 5px;
        font-size: 1.1rem;
        font-weight: 600;
        border-color: #fa0000;
        color: #fa0000;
        background-color: #ffffff;
        border-style: solid;
        cursor: pointer;
        min-width:unset;
    }

    .delete-record-text {
        min-width: fit-content;
    }

    .delete-record-button:disabled{
        background-color: #ffffff;
        color: #cfcfcf;
        border-color: rgb(102, 100, 100);
        border-width: 1px;
        cursor:default;
    }

    .delete-record-button-container{
        display: flex;
        justify-content: left;
        padding-bottom: 1rem; 
        padding-top: 1rem;
        width: 49%;
    }

    .education-button-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .record-button-sub-container{
        display: flex;
        justify-content: space-around;
    }

    @media (min-width: 801px) {
        background-color:  white;

        .title {
            font-size:2em;
            font-weight:700;
        }
        .previousNextButtonHeader {
            padding-top:1.5rem;
            padding-bottom:1.5rem;
        }
        
        .record-container{
            background-color: #f2f2f2;
            border-radius: 7px;
            border:1px solid #000b57;
            padding: 3rem;
            display: flex;
            flex-direction: column;
            justify-content:  space-evenly;
            padding-right: 2rem;
            padding-left: 2rem;  
        }
        

        .saved-record-container{
            background-color: #f1fafc;
            border-radius: 7px;
            border:1px solid #000b57;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 2rem 2rem 2rem 2rem;
            font-size: 20px;
        }


        .verified-text {
        font-size: unset;
    }
    }
}