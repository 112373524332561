#TimesheetShifts{
    
    .timesheet-header {
        width: 100%;
        background-color:  #226212;
        padding: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2.75rem;
        font-size: 20px;
        color:#fff;
        font-weight: 600;
    }
    
    .TimesheetShifts{
        justify-content: center;
        display: flex;
      //  flex-grow: 1;
       // overflow: auto;
        padding: 1.5rem; 
    }
    .timesheet-shifts-data{
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        flex-grow: 1;
    }
    .timesheet-shifts-title {
        font-size: 29px;
        font-weight: 600;
        padding: 20px 20px 0px;
        margin-bottom: 2rem;
    }
    .timesheet-shifts-title-bottom-border {
        border-bottom: 4px solid #040b50;
    }

    .check { 
        height: 41px; 
        width: 20px; 
        border-bottom: 4px solid white; 
        border-right: 4px solid white; 
        transform: rotate(45deg); 
        margin: -5px;
    } 

    .check-container{
        padding-bottom: 1rem;
    }

    .delete-record-button{
        display: flex;
        justify-content: space-around;
        height: 2.5rem;
        padding-top: 2px; 
        border-radius: 5px;
        font-size: 1.1rem;
        font-weight: 600;
        border-style: solid;
        cursor: pointer;
        border-color: #fa0000;
        color: #fa0000;
        background-color: #ffffff;
        align-items: center;
        min-width: auto;
    }

    .delete-record-text {
        min-width: fit-content;
    }

    .delete-record-button:disabled{
        background-color: #ffffff;
        color: #cfcfcf;
        border-color: rgb(102, 100, 100);
        border-width: 1px;
        cursor:default;
    }

    .delete-record-button-container{
        display: flex;
        justify-content: left;
        padding-bottom: 1rem; 
        width: 49%;
    }
  
    .new-record-button-content{
        background-color: white;
        padding-left: 1.5rem
    }
        
    .new-time-button {
        width: 20%;
        padding: 0.5em;
        border-radius: 5px;
        background-color:white;
        box-sizing: border-box;
        font-family: "Barlow SemiBold", "Barlow", sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        color: rgba(4, 11, 80, 0.99);
        text-align: left;
        line-height: normal;
        display: flex;
        align-items: center; 
        gap: .5em;
        border: 1px solid #040b50;
    }
 

    @media (max-width: 1250px) {
        .timesheet-shifts-title {
            font-size: 16px;
            font-weight: 600;
            padding: 20px 20px 0px;
            border-bottom: 1px solid #040b50;
            margin-bottom: 2rem;
        }
        .TimesheetShifts{
            padding:0.75rem;
        }
        .new-record-button-content{
            background-color: white;
            padding: .75rem
        }
        .new-time-button {
            width: 40%;
            padding: 0.5em;
            border-radius: 5px;
            background-color:white;
            box-sizing: border-box;
            font-family: "Barlow SemiBold", "Barlow", sans-serif;
            font-size: 1.2rem;
            font-weight: 600;
            color: rgba(4, 11, 80, 0.99);
            text-align: left;
            line-height: normal;
            display: flex;
            align-items: center; 
            gap: .5em;
            border: 1px solid #040b50;
        }
     

        
    }
}