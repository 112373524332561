.loadingOverlayContainer {
    width: 100%;
    height: calc(96vh - 3em);
    overflow: hidden;
    background-color: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: absolute;
    border-radius: 10px;
}
.loadingOverlaySubContainer {
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    font-size: 2rem;
    font-weight: 600;
    gap: 1rem;
}