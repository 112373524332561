#JobSearchResults{
  display: flex;
  // background: rgba(225, 225, 225, 0.5);
  flex-direction: row;
  overflow: hidden;
  gap:2rem;
  flex-grow:1;
  padding:1rem;
  min-height:85dvh;

  .left-pane-container{
    display: flex;
    flex-direction: column;
    flex-grow:1;
    overflow: auto;
    border: 1px solid #797979;
    min-width:25rem;
    max-width:25rem;
    border-radius: 10px;
  }

  .job-details-left-pane {
    display: flex;
    flex-direction: column;
    flex-grow:1;
    overflow: auto;
  }
  .left-pane-header{
    display: flex;
    flex-direction: column;
    background-color: #135786;
    padding:.75rem;
    gap:.75rem;
  }
  .left-pane-header-job-openings{
    color:white;
    font-weight: bold;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width:95%;
  }
  .left-pane-header-filters{
    display: flex;
    gap:.5rem;
    align-items: center;
  }
  .sort-label{
    color: white;
    font-weight: bold;
    margin-bottom:.1rem
  }

  .right-pane-container{
    display: flex;
    flex-grow: 1;
    overflow: auto;
  }

  .job-details-right-pane{
    display: flex;
    flex-grow: 1;
    overflow: auto;
  }

  .left-pane-with-secondary-header {
    display: flex;
    flex-direction: column;
    border: 1px solid #797979;
    border-radius: 10px;
    overflow: hidden;
    flex-grow:1;
  }
  .jobSortOrderFilter {
    height: 2em;
    margin: 0;
    display: flex;
    flex-direction: row;
    gap: .5em;
    padding-bottom: 0.75em;
    padding-left: 0.75em;
    background: #135786;
    color: #fff;
    font-weight: bold;
  }
  .jobListStyle {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow:auto;
    background-color: #fff;
    padding:1rem;
    gap:1rem;
  }
  .sort-options{
    height: 2em;
    align-self:center;
    font-family: "Barlow", sans-serif;
    color: #000B57;
  }

  .mobile-filter,
  .mobile-sort-options {
    display: none;
  }

  .desktopHeader {
    display: flex;
    flex-grow: 1;
    overflow: auto;
    background-color: #f5fcfd;
    padding: 1rem;
    border-bottom: 1px solid #797979;
    height: 4rem;
  }
  .findAJobDesktop {
    font-size: x-large;
    font-weight: bold;
  }
  .sort-label{
    align-self: center;
  }
  .noMatchesFound{
    padding: 2.5em;
    line-height: 1.5em;
  }
  .jobListStyle .noMatchesFound {
    padding-left: .75rem;
  }
  .titleLine {
  font-weight: bold;
  font-size: 2rem;
  }
  .subtitle {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .registerButtonContainer {
    cursor: pointer;
    background-color: white;
    padding: 5px 40px;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #020c57;
    text-align: center;
    line-height: normal;
    border: none;
    font-size: unset;
    border: 1px solid #020c57;
  }

  .dontWorry {
    font-size: 1.2rem;
    font-weight: 300;
    border-bottom: 1px solid #040b50;
    width: fit-content;
  }

  
  @media (max-width: 1250px) {
    gap:unset;
    min-height:80dvh;
    padding:unset;
    justify-content: center;
    padding-top:2rem;

    .containerStyle {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      align-items: center;
      flex-grow: 1;
      overflow: auto;
    }

    .mobile-filter {
      display: flex;
      flex-direction: column;
      border-bottom: solid 1px #797979;
      font-weight: bold;
    }
    .sort-options{
      border-radius: 5px;
    }
    .left-pane-container{
      min-width: 30%;
      max-width: 70%;
    }
    .desktopHeader {
      display: none;
    }
    .noMatchesFound{
      padding: 1em;
      font-weight: unset;
    }

    .titleLine {
      font-size: 1.2rem;
    }
    .subtitle {
      font-size: 1rem;
    }
    .dontWorry {
      font-size: 1rem;
    }
    
    body{
      overflow-y: hidden;
    }

    .left-pane-with-secondary-header {
      max-width: 51rem;
      justify-content: center;
    }
  }

  @media (max-width:800px){
    .left-pane-container{
      min-width: 30%;
      max-width: 90%;
    }
  }
}
