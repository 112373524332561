.wrapper {
    display: flex;
    flex-direction: column;
}

.archived {
    color: #7D7D7D;
}

.rateDetails {
    display: flex;
    flex-direction: row;
    margin: .5rem 2rem 0rem 2rem;
    font-size: 16px;
    line-height: 24px;
}
.rateDetailsHeader {
    border-bottom-color: #040b50;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding-top: 1rem;
    padding-bottom: 2px;
}
.payDetailItems {
    width: 20rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-top: .5rem;
}
.bonuses {
    padding: 1rem 2rem .5rem 2rem;
    font-size: 16px;
    line-height: 24px;
}
.highlight {
    background-color: #bbe7f2;
    padding: .125rem .5rem .125rem .5rem;
}
.mainPaySection {
    flex-grow: 1;
    overflow: hidden;
}
.bonusSubHeader {
    border-bottom-color: #040b50;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-bottom: .5rem;
}
.ratesAndStipends {
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
    margin-right: 2rem;
    flex-flow: wrap;
}
.table {
    display: grid;
    grid-template-columns: max-content max-content auto;
}
.equals-cells {
    display: none;
}
.calculation-cell {
    padding: 0 1rem;
}
.total-cell {
    display: flex;
    justify-content: flex-start;
    padding: unset;
}
.first-cell.highlight {
    margin-left: -0.5rem;
}


@media (max-width: 800px) {
    .rateDetailsHeader {
        padding-bottom: 0;
        display: flex;
        justify-content: space-between;
    }
    .highlight {
        padding: 0.125rem 0.5rem 0rem 0.5rem
    }
    .ratesAndStipends {
        margin-left: 1rem;
    }
    .bonuses {
        padding-left: 1rem;
    }
    .table {
        grid-template-columns: auto max-content;
    }
    .equals-cells {
        display: flex;
        flex-direction: column;
        padding: unset;
    }
    .calculation-cell {
        padding: unset;
    }
    .desktop-cell {
        display: none;
    }
}